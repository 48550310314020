@charset "UTF-8";

// ----------------------------------------
//  ios.js読み込み時の100vh設定
//  height: calc(var(--vh, 1vh) * 100);
// ----------------------------------------

// ----------------------------------------
//  Breakpoint
// ----------------------------------------

$pc-width:960px;
$pc-width-in:800px;
$breakpoint:768px;

$breakpoint-xs: 480px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;

// ----------------------------------------
//  Font
// ----------------------------------------

// $font-family-base: '游ゴシック体', 'Yu Gothic', YuGothic, 'メイリオ', Meiryo, Quicksand, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$font-family-base: 'Noto Sans JP', sans-serif;
$font-size-base: 1.4rem;
$base-line-height: 1.65;

@mixin mincho {
  // font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-family: 'Noto Serif JP', serif;
}
@mixin en {
  font-family: 'Roboto', sans-serif;
}

// 文字詰設定
// font-feature-settings: "palt";

// CSS Transformsで画面がちらつくとき
// -webkit-backface-visibility: hidden;
// backface-visibility: hidden;

// ----------------------------------------
//  Color
// ----------------------------------------

$c-main: #001976;
$c-blue: #3A58C2;
$c-darkblue: #02173D;
$c-black: #222;
$c-white: #fff;
$c-gray: #eee;
$c-form-gray: #aaa;
$c-yellow: #FFCC00;
$c-orange: #ff9300;
$c-red: #FF2805;

@charset "UTF-8";
/* ----------------------------------------
 Reset
---------------------------------------- */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700|Noto+Serif+JP:400,700|Roboto:400,700&display=swap&subset=japanese");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul, li {
  list-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

/* ----------------------------------------
 Fonts
---------------------------------------- */
/* ----------------------------------------
 Base
---------------------------------------- */
html {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 62.5%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  color: #222;
  width: 100%;
  margin: 0 auto;
  font-size: 1.4rem;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.65;
  letter-spacing: 0.05em;
  -ms-overflow-style: none;
}
body::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  font-style: normal;
  list-style: none;
  box-sizing: border-box;
}
*::after, *::before {
  box-sizing: border-box;
}

a {
  color: #3A58C2;
  text-decoration: none;
  border: none;
  outline: none;
}
a img {
  transition: all 0.2s;
}

svg {
  vertical-align: middle;
}

a:focus, *:focus {
  outline: none;
}

br {
  line-height: 1;
}

img {
  vertical-align: top;
}

input[type=button], input[type=submit], select, button {
  cursor: pointer;
}

::-ms-expand {
  display: none;
}

body.ios a img {
  opacity: 1;
}

/* IE8+, Chrome */
input, button {
  outline: 0;
}

/* Firefox */
input::-moz-focus-inner, button::-moz-focus-inner {
  border: 0;
}

input[type=button], input[type=submit], input[type=text], input[type=email], select, button, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

input::-webkit-input-placeholder,
input:-ms-input-placeholder,
input::-moz-placeholder {
  color: #aaa;
  font-weight: normal;
}

/*clearfix --------------------------- */
.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.cl {
  clear: both;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.pc {
  display: block;
}

.sp {
  display: none;
}

.pc-in {
  display: inline-block;
}

.sp-in {
  display: none;
}

.pc-table {
  display: table;
}

.sp-table {
  display: none;
}

@media only screen and (max-width: 768px) {
  body {
    min-width: inherit;
  }

  img {
    width: 100%;
  }

  a:hover img {
    opacity: 1;
  }

  body.ios input[type=text],
body.ios input[type=email],
body.ios select,
body.ios textarea {
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }

  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  .pc-in {
    display: none;
  }

  .sp-in {
    display: inline-block;
  }

  .pc-table {
    display: none;
  }

  .sp-table {
    display: table;
  }
}
/* ========================================
 Common Setting
======================================== */
html.is-block {
  overflow: hidden;
}

body {
  padding-top: 100px;
}
@media only screen and (max-width: 768px) {
  body {
    padding-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  html.is-block #content-wrapper {
    -webkit-animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    -moz-animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}
#content-wrapper .bg-gray {
  background: #eee;
}

.content-width {
  width: 100%;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}
.content-width .content-width-in {
  width: 100%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (max-width: 960px) {
  .content-width {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.content-inner {
  padding-top: 100px;
  padding-bottom: 150px;
}
@media only screen and (max-width: 768px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

#pagetop {
  position: relative;
}
#pagetop.is-fixed a {
  position: fixed;
  right: 20px;
  bottom: 0;
}
#pagetop a {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001976;
  width: 60px;
  height: 60px;
  position: absolute;
  z-index: 8888;
  right: 20px;
  bottom: 0;
  margin-bottom: 20px;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  transition: all 0.2s;
}
#pagetop a::before, #pagetop a::after {
  content: "";
  transition: all 0.2s;
}
#pagetop a::before {
  width: 2px;
  height: 14px;
  background: #fff;
}
#pagetop a::after {
  width: 12px;
  height: 12px;
  border-top: solid #fff 2px;
  border-right: solid #fff 2px;
  transform: rotate(-45deg);
  position: absolute;
  margin-top: -2px;
}
#pagetop a:hover {
  background: #3A58C2;
}
@media only screen and (max-width: 768px) {
  #pagetop {
    display: none;
    height: 60px;
  }
  #pagetop.is-fixed a {
    right: 10px;
  }
  #pagetop a {
    width: 40px;
    height: 40px;
    right: 10px;
    margin-bottom: 10px;
  }
}

.flex-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-wrap.abs {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

h2 {
  text-align: center;
  margin-bottom: 50px;
  padding-bottom: 50px;
  position: relative;
}
h2:after {
  content: "";
  background-color: #222;
  width: 64px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -32px;
}
h2 .en {
  font-family: "Roboto", sans-serif;
  font-size: 2.2rem;
}
h2 .jp {
  color: #001976;
  font-family: "Noto Serif JP", serif;
  font-size: 3.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 768px) {
  h2 {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  h2:after {
    width: 50px;
  }
  h2 .en {
    font-size: 1.4rem;
  }
  h2 .jp {
    font-size: 2.4rem;
  }
}

/* ----------------------------------------
 Button
---------------------------------------- */
.btn-wrap {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
}
.btn-wrap::after {
  content: "";
  width: 12px;
  height: 12px;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  position: absolute;
  right: 30px;
  top: 50%;
  z-index: 1;
  margin-top: -6px;
  transform: rotate(45deg);
  transition: all 0.2s;
}
.btn-wrap.is-left::after {
  right: auto;
  left: 30px;
  transform: rotate(-135deg);
}
.btn-wrap input[type=submit] {
  margin: 0;
}

.btn-area {
  display: flex;
  justify-content: center;
}

input[type=submit],
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #222;
  width: 160px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 40px;
  transition: all 0.2s;
}
body:not(.smp) input[type=submit]:hover,
body:not(.smp) .btn:hover {
  background: #3A58C2;
  color: #fff;
}
body:not(.smp) input[type=submit]:hover.btn-contact,
body:not(.smp) .btn:hover.btn-contact {
  background: #3A58C2;
  color: #fff;
}
body:not(.smp) input[type=submit]:hover.btn-contact svg,
body:not(.smp) .btn:hover.btn-contact svg {
  fill: #fff;
}
body:not(.smp) input[type=submit]:hover.is-blue,
body:not(.smp) .btn:hover.is-blue {
  background: #3A58C2;
  color: #fff;
}
body:not(.smp) input[type=submit]:hover.is-yellow,
body:not(.smp) .btn:hover.is-yellow {
  background: #3A58C2;
  color: #fff;
}
input[type=submit] span,
.btn span {
  display: inline-block;
  padding-bottom: 3px;
}
input[type=submit].btn-contact,
.btn.btn-contact {
  background: #FFF;
  color: #001976;
}
input[type=submit].btn-contact svg,
.btn.btn-contact svg {
  transition: all 0.2s;
}
input[type=submit].is-blue,
.btn.is-blue {
  background: #001976;
  color: #fff;
}
input[type=submit].is-yellow,
.btn.is-yellow {
  background: #FFCC00;
  color: #001976;
  letter-spacing: 0;
}
input[type=submit].is-big,
.btn.is-big {
  width: 420px;
  height: 80px;
  font-size: 2rem;
  position: relative;
}
input[type=submit].is-big::after,
.btn.is-big::after {
  content: "";
  width: 12px;
  height: 12px;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -6px;
  transform: rotate(45deg);
  transition: all 0.2s;
}
input[type=submit].is-big.is-left::after,
.btn.is-big.is-left::after {
  right: auto;
  left: 30px;
  transform: rotate(-135deg);
}
input[type=submit] .btn-in,
.btn .btn-in {
  position: relative;
  padding-left: 20px;
}
input[type=submit] .btn-in svg,
.btn .btn-in svg {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -5.6px;
  fill: #001976;
}
@media only screen and (max-width: 768px) {
  input[type=submit],
.btn {
    font-size: 1.3rem;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: 0.02em;
  }
  input[type=submit].is-big,
.btn.is-big {
    width: 100%;
    max-width: 280px;
    height: 60px;
    font-size: 1.6rem;
  }
  input[type=submit].is-big::after,
.btn.is-big::after {
    right: 20px;
  }
}

/* ----------------------------------------
 Header
---------------------------------------- */
header {
  background: rgba(255, 255, 255, 0.96);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
header .header-in {
  display: flex;
  justify-content: space-between;
}
header .logo {
  display: flex;
  align-items: center;
  margin-left: 3.90625%;
  transition: all 0.2s;
}
header .logo svg {
  fill: #001976;
  transition: all 0.2s;
}
header .nav-wrap {
  overflow: hidden;
}
header nav {
  display: flex;
  align-items: center;
  height: 100px;
  margin-right: 35px;
  float: left;
  font-size: 1.5rem;
  line-height: 1.2;
}
header nav .inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
header nav a {
  color: #001976;
}
header nav a.active span:after {
  width: 100%;
  left: 0;
}
body:not(.smp) header nav a:hover span:after {
  width: 100%;
  left: 0;
}
header nav a span {
  display: inline-block;
  margin: 0 15px;
  padding: 6px 0;
  position: relative;
}
header nav a span:after {
  content: "";
  background: #FFCC00;
  width: 0;
  height: 2px;
  transition: all 0.2s;
  position: absolute;
  right: 0;
  bottom: 0;
}
header nav a span br {
  display: none;
}
header .contact-area {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001976;
  color: #FFF;
  width: 200px;
  height: 100px;
  text-align: center;
  float: right;
}
header .contact-area .btn-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: bold;
}
header .contact-area .tel {
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1;
}
header .contact-area .tel span {
  font-size: 1.3rem;
}
header .contact-area .tel .num {
  display: inline-block;
  margin-left: 0.3em;
  font-size: 1.7rem;
}
@media only screen and (max-width: 1280px) {
  header .logo {
    width: 110px;
    margin-left: 2.34375%;
  }
  header .logo svg {
    width: 100%;
  }
  header nav {
    margin-right: 15px;
  }
  header nav a span {
    margin: 0 10px;
  }
  header .contact-area {
    width: 180px;
  }
}
@media only screen and (max-width: 1080px) {
  header nav {
    font-size: 1.3rem;
  }
  header nav a span {
    margin: 0 6px;
  }
  header nav a span br {
    display: inline;
  }
}
@media only screen and (max-width: 960px) {
  header .logo {
    width: 90px;
  }
  header .contact-area .btn-contact {
    width: 140px;
  }
  header .contact-area {
    width: 160px;
  }
}
@media only screen and (max-width: 768px) {
  header {
    background: none;
    height: auto;
    position: absolute;
  }
  header .header-in {
    display: block;
    position: relative;
  }
  header .header-in::after {
    content: "";
    width: 100%;
    height: 80px;
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 8888;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
  }
  body.is-scroll header .header-in::after {
    opacity: 1;
    visibility: visible;
  }
  header .logo {
    margin-left: 0;
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 9999;
  }
  header .logo svg {
    width: 90px;
    height: 54.65px;
    fill: #fff;
    transition: all 0.2s;
  }
  body.is-scroll header .logo svg {
    width: 70px;
    height: 42.5px;
  }
  header .sp-navbtn {
    padding-top: 4px;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }
  html.is-block header .sp-navbtn .bar::before {
    transform: translate3d(0, 8px, 0) rotate(45deg);
  }
  html.is-block header .sp-navbtn .bar::after {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
  }
  html.is-block header .sp-navbtn .bar .bar-c {
    opacity: 0;
  }
  header .sp-navbtn .bar {
    position: relative;
  }
  header .sp-navbtn .bar::before, header .sp-navbtn .bar::after {
    content: "";
    width: 30px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }
  header .sp-navbtn .bar::before {
    top: -8px;
  }
  header .sp-navbtn .bar::after {
    bottom: -8px;
  }
  header .sp-navbtn .bar .bar-c {
    background: #fff;
    width: 30px;
    height: 1px;
    margin: 8px 0;
    transition: all 0.2s;
  }
  header .sp-navbtn .text {
    margin-top: 16px;
    color: #fff;
    font-size: 1rem;
    line-height: 1;
  }
  header .nav-wrap {
    background: #222;
    color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9990;
    width: 100%;
    height: 100%;
    overflow: auto;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
  }
  html.is-block header .nav-wrap {
    transform: translate3d(0, 0, 0);
  }
  header .nav-wrap .nav-wrap-in {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  header nav {
    display: block;
    width: 100%;
    height: auto;
    margin-right: 0;
    float: none;
    font-size: 1.5rem;
  }
  header nav .inner {
    display: block;
  }
  header nav a {
    display: block;
    color: #fff;
    position: relative;
    margin-bottom: 6px;
    padding-left: 20px;
  }
  header nav a::after {
    content: "";
    width: 12px;
    height: 1px;
    background: #FFCC00;
    position: absolute;
    left: 0;
    top: 50%;
  }
  header nav a span {
    display: inline-block;
    margin: 0;
    padding: 6px 0;
    position: relative;
  }
  header nav a span:after {
    content: "";
    background: #FFCC00;
    width: 0;
    height: 2px;
    transition: all 0.2s;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  header nav a span br {
    display: none;
  }
  header .contact-area {
    background: none;
    width: 100%;
    height: auto;
    float: none;
    margin-top: 20px;
  }
}

/* ----------------------------------------
 Footer
---------------------------------------- */
footer .info {
  background: #222;
  padding: 50px 30px;
}
footer .info .logo-set {
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .info .logo-set figure svg {
  fill: #fff;
  width: 130px;
  height: 78.94px;
}
footer .info .logo-set .text {
  color: #fff;
  margin-left: 30px;
  font-size: 1.5rem;
  line-height: 2;
}
footer .info .btn-area {
  margin-top: 30px;
}
footer .info .btn-area a {
  width: 280px;
}
footer .info .btn-area a .btn-in {
  padding-left: 24px;
}
footer .info .btn-area .text-link {
  display: block;
  width: auto;
  margin-top: 40px;
  color: #fff;
  text-align: center;
  text-decoration: underline;
}
footer .copy {
  color: #666;
  padding: 30px;
  font-size: 1.3rem;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  html.is-block footer {
    -webkit-animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    -moz-animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  footer .info {
    background: #222;
    padding: 50px;
  }
  footer .info .logo-set {
    display: block;
    text-align: center;
  }
  footer .info .logo-set figure svg {
    fill: #fff;
    width: 130px;
    height: 78.94px;
  }
  footer .info .logo-set .text {
    color: #fff;
    margin-top: 30px;
    margin-left: 0px;
    font-size: 1.3rem;
    line-height: 2;
    text-align: center;
  }
  footer .info .btn-area a {
    width: 240px;
  }
  footer .copy {
    padding: 20px 5px;
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}

/* ----------------------------------------
 Animation
---------------------------------------- */
@-webkit-keyframes FadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@-moz-keyframes FadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes FadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(15px, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@-webkit-keyframes FadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-15px, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@-moz-keyframes FadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-15px, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes FadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-15px, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
/* ----------------------------------------
 Loading
---------------------------------------- */
.loading {
  background: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .sk-circle {
  width: 40px;
  height: 40px;
  position: absolute;
}
.loading .sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.loading .sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #3A58C2;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.loading .sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.loading .sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.loading .sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.loading .sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.loading .sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.loading .sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.loading .sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.loading .sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.loading .sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.loading .sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.loading .sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.loading .sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loading .sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loading .sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loading .sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.loading .sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loading .sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.loading .sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.loading .sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.loading .sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.loading .sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.loading .sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ========================================
 TOP Setting
======================================== */
.achievement-content figure {
  line-height: 0;
}
.achievement-content figure .slick-dots {
  bottom: 30px;
}
.achievement-content figure .slick-dots li:after {
  height: 6px;
}
.achievement-content figure .slick-dots li button {
  width: 40px;
  height: 6px;
}

.slick-dots {
  width: 100%;
  height: 4px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 50px;
}
.slick-dots li {
  display: inline-block;
  height: 4px;
  margin: 0 10px;
  position: relative;
}
.slick-dots li.slick-active:after {
  left: 0;
  width: 100%;
}
.slick-dots li:after {
  content: "";
  background-color: #FFCC00;
  width: 0;
  height: 4px;
  position: absolute;
  right: 0;
  top: 0;
  transition: width 0.4s;
}
.slick-dots li button {
  display: block;
  background-color: #fff;
  width: 100px;
  height: 4px;
  text-indent: -9999px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.main-visual {
  background-color: #222;
  width: 100%;
  height: 800px;
  position: relative;
}
.main-visual .main-title {
  width: 100%;
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 1;
}
.main-visual .main-title .inner {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
}
.main-visual .main-title .text-wrap {
  display: inline-block;
  background: rgba(0, 0, 0, 0.64);
  color: #fff;
  padding: 50px;
}
.main-visual .main-title .text-wrap .text1 {
  margin-bottom: 80px;
  font-size: 5.8rem;
  line-height: 1.38;
  position: relative;
  font-family: "Noto Serif JP", serif;
}
.main-visual .main-title .text-wrap .text1:after {
  content: "";
  width: 120px;
  height: 1px;
  background-color: #FFCC00;
  position: absolute;
  left: 0;
  bottom: -40px;
}
.main-visual .main-title .text-wrap .text2 {
  font-size: 1.8rem;
  line-height: 2;
}
.main-visual .slider-wrap {
  width: 100%;
  height: 800px;
  overflow: hidden;
}
.main-visual .slider-wrap .slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 800px;
  position: relative;
}
.main-visual .slider-wrap .slide::after {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .main-visual {
    height: 100%;
    min-height: 600px;
  }
  html.is-block .main-visual {
    -webkit-animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    -moz-animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    animation: FadeInLeft 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95) reverse;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .main-visual .main-title .inner {
    padding: 0 20px;
    text-align: center;
  }
  .main-visual .main-title .text-wrap {
    display: inline-block;
    background: rgba(0, 0, 0, 0.64);
    color: #fff;
    padding: 25px;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .main-visual .main-title .text-wrap .text1 {
    margin-bottom: 40px;
    font-size: 2.8rem;
    line-height: 1.25;
  }
  .main-visual .main-title .text-wrap .text1:after {
    content: "";
    width: 64px;
    bottom: -20px;
  }
  .main-visual .main-title .text-wrap .text2 {
    font-size: 1.2rem;
    line-height: 1.66;
  }
  .main-visual .slider-wrap {
    height: 100%;
  }
  .main-visual .slider-wrap .slick-list {
    height: 100%;
  }
  .main-visual .slider-wrap .slick-list .slick-track {
    height: 100%;
  }
  .main-visual .slider-wrap .slick-list .slick-track .slick-slide {
    height: 100%;
  }
  .main-visual .slider-wrap .slick-list .slick-track .slick-slide > div {
    height: 100%;
  }
  .main-visual .slider-wrap .slick-list .slick-track .slick-slide > div .slide {
    height: 100%;
  }
  .main-visual .slider-wrap .slick-dots {
    width: 100%;
    height: 4px;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 20px;
  }
  .main-visual .slider-wrap .slick-dots li {
    display: inline-block;
    height: 4px;
    margin: 0 6px;
    position: relative;
  }
  .main-visual .slider-wrap .slick-dots li.slick-active:after {
    left: 0;
    width: 100%;
  }
  .main-visual .slider-wrap .slick-dots li:after {
    content: "";
    background-color: #FFCC00;
    width: 0;
    height: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: width 0.4s;
  }
  .main-visual .slider-wrap .slick-dots li button {
    display: block;
    background-color: #fff;
    width: 30px;
    height: 4px;
    text-indent: -9999px;
  }
}

.service-block .service-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.service-block .service-list li {
  display: flex;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
.service-block .service-list li.is-puzzle {
  width: 100%;
  margin-bottom: 60px;
  justify-content: center;
}
.service-block .service-list li.is-puzzle .lp-block,
.service-block .service-list li.is-puzzle .info-block {
  width: 50%;
  min-height: 480px;
}
.service-block .service-list li.is-puzzle .lp-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001976;
  color: #fff;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.05em;
  position: relative;
}
.service-block .service-list li.is-puzzle .lp-block::after {
  content: "";
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 0;
  border: solid 1px #3A58C2;
}
.service-block .service-list li.is-puzzle .lp-block .flex-in {
  width: 100%;
  position: relative;
  z-index: 1;
}
.service-block .service-list li.is-puzzle .lp-block .lead {
  font-style: 1.5rem;
}
.service-block .service-list li.is-puzzle .lp-block .icons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.service-block .service-list li.is-puzzle .lp-block .icons li {
  display: inline-block;
  width: 16.6666666667%;
  height: auto;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2.0833333333%;
  margin-left: 2.0833333333%;
}
.service-block .service-list li.is-puzzle .lp-block .icons li svg {
  width: 100%;
}
.service-block .service-list li.is-puzzle .lp-block .title {
  margin-top: 40px;
  padding-top: 40px;
  font-size: 2.4rem;
  position: relative;
}
.service-block .service-list li.is-puzzle .lp-block .title::before {
  content: "";
  background: #fff;
  width: 64px;
  height: 1px;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -32px;
}
.service-block .service-list li.is-puzzle .lp-block .title span {
  font-size: 3.4rem;
  color: #FFCC00;
}
.service-block .service-list li.is-puzzle .info-block .photo {
  height: 240px;
}
.service-block .service-list li.is-puzzle .info-block .photo .category {
  font-size: 3rem;
}
.service-block .service-list li.is-puzzle .info-block .photo .category i svg {
  width: 60px;
  height: 60px;
}
.service-block .service-list li.is-2nd {
  width: 46.875%;
  margin-bottom: 60px;
}
.service-block .service-list li.is-2nd .info-block .photo {
  height: 225px;
}
.service-block .service-list li.is-2nd .info-block .photo .category {
  font-size: 3rem;
}
.service-block .service-list li.is-2nd .info-block .photo .category i svg {
  width: 50px;
  height: 50px;
}
.service-block .service-list li.is-3rd {
  width: 31.25%;
}
.service-block .service-list li.is-3rd .info-block {
  min-height: auto;
}
.service-block .service-list li.is-3rd .info-block .photo {
  height: 150px;
}
.service-block .service-list li.is-3rd .info-block .photo .category {
  font-size: 2.4rem;
}
.service-block .service-list li.is-3rd .info-block .photo .category i {
  margin-bottom: 12px;
}
.service-block .service-list li.is-3rd .info-block .photo .category i svg {
  width: 44px;
  height: 44px;
}
.service-block .service-list li.is-3rd .info-block .information {
  height: auto;
}
.service-block .service-list li .info-block {
  background: #fff;
  width: 100%;
  min-height: 450px;
}
.service-block .service-list li .info-block .photo {
  width: 100%;
  position: relative;
}
.service-block .service-list li .info-block .photo::before {
  content: "";
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.service-block .service-list li .info-block .photo figure {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-block .service-list li .info-block .photo .category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-weight: bold;
  color: #fff;
}
.service-block .service-list li .info-block .photo .category i {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
.service-block .service-list li .info-block .photo .category i svg {
  fill: #FFCC00;
}
.service-block .service-list li .info-block .photo .category .en {
  font-family: "Roboto", sans-serif;
  line-height: 1;
  letter-spacing: 0.1em;
}
.service-block .service-list li .info-block .information {
  position: relative;
  height: 50%;
  padding: 20px 28px 30px;
}
.service-block .service-list li .info-block .information h3 {
  color: #001976;
  font-size: 2.6rem;
  font-weight: bold;
  font-family: "Noto Serif JP", serif;
  text-align: center;
}
.service-block .service-list li .info-block .information .lead {
  margin-top: 10px;
  font-size: 1.4rem;
  letter-spacing: 0;
}
.service-block .service-list li .info-block .information .btn-area {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
}
@media only screen and (max-width: 960px) {
  .service-block .service-list {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 768px) {
  .service-block .service-list {
    padding: 0;
  }
  .service-block .service-list li {
    display: block;
    margin-top: 30px;
  }
  .service-block .service-list li:first-child {
    margin-top: 0;
  }
  .service-block .service-list li.is-puzzle {
    width: 100%;
    margin-bottom: 0;
  }
  .service-block .service-list li.is-puzzle .lp-block,
.service-block .service-list li.is-puzzle .info-block {
    width: 100%;
    min-height: auto;
  }
  .service-block .service-list li.is-puzzle .lp-block {
    background: #001976;
    color: #fff;
    padding: 50px 0;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    position: relative;
  }
  .service-block .service-list li.is-puzzle .lp-block .flex-in {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .service-block .service-list li.is-puzzle .lp-block .lead {
    font-style: 1.5rem;
  }
  .service-block .service-list li.is-puzzle .lp-block .icons {
    margin-top: 10px;
  }
  .service-block .service-list li.is-puzzle .lp-block .icons li {
    display: inline-block;
    width: 16.6666666667%;
    height: auto;
    margin: 0 2.0833333333%;
  }
  .service-block .service-list li.is-puzzle .lp-block .icons li svg {
    width: 100%;
  }
  .service-block .service-list li.is-puzzle .lp-block .title {
    margin-top: 20px;
    padding-top: 20px;
    font-size: 1.5rem;
    position: relative;
  }
  .service-block .service-list li.is-puzzle .lp-block .title::before {
    content: "";
    background: #fff;
    width: 50px;
    height: 1px;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -25px;
  }
  .service-block .service-list li.is-puzzle .lp-block .title span {
    font-size: 2.2rem;
    color: #FFCC00;
  }
  .service-block .service-list li.is-puzzle .info-block .photo {
    height: 150px;
  }
  .service-block .service-list li.is-puzzle .info-block .photo .category {
    font-size: 2rem;
  }
  .service-block .service-list li.is-puzzle .info-block .photo .category i svg {
    width: 40px;
    height: 40px;
  }
  .service-block .service-list li.is-puzzle .info-block .information .btn-area {
    display: block;
    overflow: hidden;
  }
  .service-block .service-list li.is-puzzle .info-block .information .btn-area a:nth-of-type(1) {
    float: left;
    margin: 0;
    width: 48%;
  }
  .service-block .service-list li.is-puzzle .info-block .information .btn-area a:nth-of-type(2) {
    float: right;
    margin: 0;
    width: 48%;
  }
  .service-block .service-list li.is-2nd {
    width: 100%;
    margin-bottom: 0px;
  }
  .service-block .service-list li.is-2nd .info-block .photo {
    height: 150px;
  }
  .service-block .service-list li.is-2nd .info-block .photo .category {
    font-size: 2rem;
  }
  .service-block .service-list li.is-2nd .info-block .photo .category i svg {
    width: 40px;
    height: 40px;
  }
  .service-block .service-list li.is-3rd {
    width: 100%;
  }
  .service-block .service-list li.is-3rd .info-block {
    min-height: auto;
  }
  .service-block .service-list li.is-3rd .info-block .photo {
    height: 150px;
  }
  .service-block .service-list li.is-3rd .info-block .photo .category {
    font-size: 2rem;
  }
  .service-block .service-list li.is-3rd .info-block .photo .category i {
    margin-bottom: 12px;
  }
  .service-block .service-list li.is-3rd .info-block .photo .category i svg {
    width: 40px;
    height: 40px;
  }
  .service-block .service-list li .info-block {
    background: #fff;
    width: 100%;
    min-height: auto;
  }
  .service-block .service-list li .info-block .photo {
    width: 100%;
    position: relative;
  }
  .service-block .service-list li .info-block .photo .category i {
    margin-bottom: 15px;
  }
  .service-block .service-list li .info-block .photo .category i svg {
    fill: #FFCC00;
  }
  .service-block .service-list li .info-block .photo .category .en {
    font-family: "Roboto", sans-serif;
    line-height: 1;
    letter-spacing: 0.1em;
  }
  .service-block .service-list li .info-block .information {
    position: relative;
    height: auto;
    padding: 20px 20px 30px;
  }
  .service-block .service-list li .info-block .information h3 {
    font-size: 2rem;
  }
  .service-block .service-list li .info-block .information .lead {
    margin-top: 10px;
    font-size: 1.3rem;
  }
  .service-block .service-list li .info-block .information .btn-area {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
  }
}

.staff-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/top/staff_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 480px;
  margin-bottom: 100px;
  position: relative;
}
.staff-block::before {
  content: "";
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.staff-block .inner {
  position: relative;
  z-index: 1;
}
.staff-block h2:after {
  background-color: #fff;
}
.staff-block h2 .en, .staff-block h2 .jp {
  color: #fff;
}
.staff-block .lead {
  color: #fff;
  font-size: 1.8rem;
  text-align: center;
  line-height: 2;
}
.staff-block .btn-area {
  margin-top: 50px;
}
.staff-block .btn-area a {
  width: 200px;
}
@media only screen and (max-width: 768px) {
  .staff-block {
    background-image: url(../img/top/staff_bg_sp.png);
    height: auto;
    margin-bottom: 50px;
    padding: 50px 0;
  }
  .staff-block .lead {
    font-size: 1.3rem;
  }
  .staff-block .btn-area {
    margin-top: 30px;
  }
  .staff-block .btn-area a {
    width: 160px;
  }
}

.top-btns {
  margin-top: 40px;
  text-shadow: none;
  text-align: center;
  font-size: 0;
  position: absolute;
  right: 40px;
  bottom: 80px;
  z-index: 1;
}
@media only screen and (max-width: 768px) {
  .top-btns {
    width: 100%;
    right: 0;
    bottom: 50px;
    padding: 0 20px;
  }
}
.top-btns a {
  display: inline-block;
  background: #fff;
  color: #001976;
  margin: 10px 20px 15px;
  font-size: 14px;
  line-height: 1;
  border-radius: 6px;
  transition: 300ms all ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .top-btns a {
    line-height: 1.5;
    max-width: 420px;
    margin: 0 auto;
  }
}
.top-btns a .btn-wrap {
  display: table;
  width: 100%;
  margin: 0;
}
.top-btns a .btn-wrap::after {
  display: none;
}
.top-btns a .icon {
  background: #001976;
  display: table-cell;
  width: 60px;
  position: relative;
  transition: 300ms all ease-in-out;
}
.top-btns a img {
  width: 64%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-btns a .btn-right {
  display: table-cell;
  background: #fff;
  font-weight: bold;
  text-align: left;
  padding: 15px;
}
@media only screen and (max-width: 768px) {
  .top-btns a .btn-right {
    padding: 10px 15px;
  }
}
.top-btns a .text1 {
  display: inline-block;
  color: #333;
  margin-bottom: 10px;
  font-size: 11px;
  transition: 300ms all ease-in-out;
  font-weight: normal;
}
@media only screen and (max-width: 768px) {
  .top-btns a .text1 {
    margin-bottom: 5px;
  }
}
.top-btns a .text2 {
  font-size: 11px;
  display: block;
  margin-top: 5px;
}
.top-btns a .text2 br {
  display: none;
}

/* ========================================
 Under Setting
======================================== */
.under-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
  position: relative;
}
.under-visual::after {
  content: "";
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.under-visual.is-achievement {
  background-image: url(../img/visual_achievement.png);
}
.under-visual.is-about {
  background-image: url(../img/visual_about.png);
}
.under-visual.is-staff {
  background-image: url(../img/visual_staff.png);
}
.under-visual.is-contact {
  background-image: url(../img/visual_contact.png);
}
.under-visual.is-page {
  background-image: url(../img/visual_page.png);
}
.under-visual h2 {
  position: relative;
  margin-bottom: 0;
  z-index: 1;
}
.under-visual h2:after {
  content: "";
  background-color: #FFCC00;
}
.under-visual h2 .en {
  color: #fff;
}
.under-visual h2 .jp {
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .under-visual {
    height: 240px;
  }
  .under-visual h2 {
    position: absolute;
    bottom: 50px;
  }
}

.breadcrumb {
  position: absolute;
  left: 0;
  top: 20px;
}
@media only screen and (max-width: 960px) {
  .breadcrumb {
    left: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .breadcrumb {
    display: none;
  }
}

.table-layout {
  width: 100%;
  margin-top: 50px;
  border-top: 1px solid #001976;
  border-bottom: 1px solid #001976;
  font-size: 1.5rem;
}
.table-layout tr {
  border-top: 1px solid #ddd;
}
.table-layout tr:nth-of-type(1) {
  border-top: none;
}
.table-layout th, .table-layout td {
  vertical-align: top;
  padding: 12px 0 15px;
}
.table-layout th {
  width: 25%;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .table-layout {
    margin-top: 40px;
    font-size: 1.3rem;
  }
  .table-layout th {
    width: 100px;
  }
}

/* ----------------------------------------
 About
---------------------------------------- */
.content-about .logo {
  text-align: center;
}
.content-about .logo svg {
  fill: #001976;
  width: 200px;
  height: 121.44px;
}
.content-about .table-layout tr.map {
  border-top: none;
}
.content-about .table-layout tr.map td {
  padding-left: 25%;
}
.content-about .table-layout tr.map td iframe {
  width: 100%;
}
.content-about .table-layout td .btn-area {
  margin: 15px 0;
}
.content-about .table-layout td .btn-area .btn {
  width: 280px;
}
.content-about .table-layout td .btn-area .btn .btn-in svg {
  fill: #fff;
  transition: all 0.2s;
}
.content-about .btn-area {
  margin-top: 60px;
  display: block;
}
.content-about .btn-area a {
  width: 280px;
  margin: 30px auto 0;
}
.content-about .btn-area a:nth-of-type(1) {
  margin-top: 0;
}
.content-about .btn-area a.btn-puzzle {
  background: #3A58C2;
  color: #fff;
}
body:not(.smp) .content-about .btn-area a.btn-puzzle:hover {
  background: #FFCC00;
  color: #001976;
}
.content-about .btn-area a.btn-contact {
  background: #001976;
  color: #fff;
}
.content-about .btn-area a.btn-contact svg {
  fill: #fff;
}
.content-about .inc-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 540px;
  height: 150px;
  margin: 60px auto 0;
  padding-right: 40px;
  border: solid 2px #001976;
  position: relative;
  color: #222;
}
.content-about .inc-block::before, .content-about .inc-block::after {
  content: "";
  position: absolute;
}
.content-about .inc-block::before {
  background: #001976;
  width: 20px;
  height: 80px;
  right: 10px;
  top: 50%;
  margin-top: -40px;
  z-index: 1;
}
.content-about .inc-block::after {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
  right: 17px;
  top: 50%;
  margin-top: -6px;
  z-index: 2;
}
.content-about .inc-block .text {
  margin-left: 40px;
  font-size: 1.6rem;
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .content-about .logo svg {
    width: 120px;
    height: 72.86px;
  }
  .content-about .table-layout tr.map td {
    padding-top: 0;
    padding-left: 0;
  }
  .content-about .table-layout tr.map td .btn-area .btn {
    width: 240px;
  }
  .content-about .table-layout tr.map td iframe {
    height: 300px;
  }
  .content-about .btn-area a {
    width: 240px;
  }
  .content-about .inc-block {
    display: block;
    max-width: 280px;
    height: auto;
    margin: 40px auto 0;
    padding: 20px 30px 15px;
  }
  .content-about .inc-block::before {
    width: 20px;
    height: 160px;
    margin-top: -80px;
  }
  .content-about .inc-block .text {
    margin-top: 20px;
    margin-left: 0px;
    font-size: 1.4rem;
  }
}

/* ----------------------------------------
 Staff
---------------------------------------- */
.content-staff {
  text-align: center;
}
.content-staff h3 {
  color: #001976;
  font-size: 3rem;
  font-family: "Noto Serif JP", serif;
  font-weight: bold;
}
.content-staff .staff-main {
  margin-top: 50px;
  padding: 50px 20px;
  font-size: 1.6rem;
  border-top: 1px solid #001976;
  border-bottom: 1px solid #001976;
  line-height: 2;
}
.content-staff .staff-main .marker {
  display: inline-block;
  color: #001976;
  padding: 0 0.2em;
  font-weight: bold;
  background: linear-gradient(transparent 50%, #FFCC00 50%, #FFCC00 85%, transparent 85%);
}
.content-staff .btn-area {
  margin-top: 50px;
}
@media only screen and (max-width: 768px) {
  .content-staff h3 {
    font-size: 2.2rem;
  }
  .content-staff .staff-main {
    margin-top: 30px;
    text-align: left;
    font-size: 1.4rem;
    padding: 30px 0px;
    line-height: 1.66;
  }
  .content-staff .staff-main .marker {
    background: linear-gradient(transparent 50%, #FFCC00 50%, #FFCC00 90%, transparent 90%);
  }
}

/* ----------------------------------------
 Contact
---------------------------------------- */
.content-contact .lead {
  margin-bottom: 50px;
  text-align: center;
  font-size: 1.6rem;
}
.content-contact ul.btn-area {
  border-top: 1px solid #001976;
  padding-top: 50px;
  justify-content: space-between;
}
.content-contact .btn-area {
  margin-top: 50px;
  text-align: center;
}
.content-contact .btn-area li {
  width: 47.5%;
}
.content-contact .btn-area li p {
  margin-bottom: 20px;
}
.content-contact .btn-area li a {
  width: 100%;
  margin: 0;
}
.content-contact .table-layout th, .content-contact .table-layout td {
  vertical-align: middle;
  padding: 20px 0;
}
.content-contact .table-layout th.vt, .content-contact .table-layout td.vt {
  vertical-align: top;
}
.content-contact .table-layout th {
  width: 30%;
}
.content-contact .table-layout th .required {
  display: inline-block;
  background: #FF2805;
  color: #fff;
  margin-left: 5px;
  padding: 2px 4px;
  font-size: 1.2rem;
  line-height: 1.2;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.content-contact .table-layout th .note {
  display: inline-block;
  margin-left: 5px;
  font-size: 1.2rem;
  font-weight: normal;
}
.content-contact input[type=text],
.content-contact input[type=email],
.content-contact textarea {
  width: 100%;
  padding: 15px;
  border: solid 1px #aaa;
  font-size: 1.6rem;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.content-contact input[type=text].is-half,
.content-contact input[type=email].is-half,
.content-contact textarea.is-half {
  width: 50%;
}
.content-contact input[type=text],
.content-contact input[type=email] {
  height: 50px;
}
.content-contact textarea {
  height: 120px;
}
.content-contact textarea#mail-content {
  height: 150px;
}
.content-contact .mw_wp_form.mw_wp_form_confirm .confirm-hide {
  display: none;
}
.content-contact .mw_wp_form.mw_wp_form_confirm .form-btns {
  margin-top: 50px;
  text-align: center;
}
.content-contact .mw_wp_form.mw_wp_form_confirm .form-btns input,
.content-contact .mw_wp_form.mw_wp_form_confirm .form-btns button {
  max-width: 320px;
  margin: 0　15px;
  display: inline-block;
}
.content-contact .mw_wp_form .horizontal-item {
  display: block;
  margin-top: 12px;
}
.content-contact .mw_wp_form .horizontal-item:first-child {
  margin-top: 0;
}
.content-contact .mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}
@media only screen and (max-width: 768px) {
  .content-contact .mw_wp_form.mw_wp_form_confirm .form-btns input,
.content-contact .mw_wp_form.mw_wp_form_confirm .form-btns button {
    margin-top: 20px;
  }
  .content-contact .mw_wp_form.mw_wp_form_confirm .form-btns input:nth-of-type(1),
.content-contact .mw_wp_form.mw_wp_form_confirm .form-btns button:nth-of-type(1) {
    margin-top: 0;
  }
}
.content-contact .radio-list li {
  margin-top: 12px;
}
.content-contact .radio-list li:first-child {
  margin-top: 0;
}
.content-contact input[type=radio] {
  display: none;
}
.content-contact input[type=radio] + span {
  display: block;
  padding-left: 32px;
  position: relative;
  line-height: 22px;
  cursor: pointer;
}
.content-contact input[type=radio] + span:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  border-radius: 24px;
  border: 1px solid #808080;
  position: absolute;
  left: 0;
  top: 0;
}
.content-contact input[type=radio] + span:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  position: absolute;
  left: 7px;
  top: 7px;
  transition: all 0.3s;
}
.content-contact input[type=radio]:checked + span:after {
  background: #4D4D4D;
}
@media only screen and (max-width: 768px) {
  .content-contact .lead {
    margin-bottom: 30px;
    font-size: 1.4rem;
  }
  .content-contact ul.btn-area {
    display: block;
  }
  .content-contact .btn-area li {
    width: 100%;
  }
  .content-contact .btn-area li:nth-of-type(1) {
    margin-bottom: 30px;
  }
  .content-contact .btn-area li p {
    margin-bottom: 10px;
  }
  .content-contact .btn-area li a {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  .content-contact .table-layout {
    display: block;
  }
  .content-contact .table-layout tbody, .content-contact .table-layout tr, .content-contact .table-layout th, .content-contact .table-layout td {
    display: block;
    width: 100%;
  }
  .content-contact .table-layout th {
    padding: 15px 0 6px;
  }
  .content-contact .table-layout th .required {
    font-size: 1.1rem;
  }
  .content-contact .table-layout td {
    padding: 0 0 20px;
  }
}

/* ----------------------------------------
 Achievement
---------------------------------------- */
.content-achievement {
  width: 100%;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.content-achievement .category-list {
  display: flex;
  justify-content: center;
}
.content-achievement .category-list ul {
  background: #fff;
  height: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  text-align: center;
}
.content-achievement .category-list li {
  display: inline-block;
  text-align: center;
}
.content-achievement .category-list li:hover a, .content-achievement .category-list li.active a {
  background: #001976;
  color: #fff;
}
.content-achievement .category-list li:first-child a {
  margin-left: 0;
}
.content-achievement .category-list li a {
  background: #fff;
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-left: 4px;
  padding: 0 15px 2px;
  font-weight: bold;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  transition: all 0.3s;
}
@media only screen and (max-width: 768px) {
  .content-achievement .category-list ul {
    background: none;
    height: auto;
  }
  .content-achievement .category-list li {
    margin-bottom: 15px;
  }
}
.content-achievement .achievement-list {
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-top: 35px;
}
.content-achievement .achievement-list .item-none {
  width: 100%;
  text-align: center;
}
.content-achievement .achievement-list a {
  display: block;
  background: #fff;
  color: #222;
  width: 30.6306306306%;
  margin: 15px 1.3513513514%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  position: relative;
}
body:not(.smp) .content-achievement .achievement-list a:hover:hover::after {
  border: solid 3px #001976;
}
.content-achievement .achievement-list a::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  border: solid 0px #001976;
  transition: all 0.1s;
}
.content-achievement .achievement-list a .icon {
  background: #001976;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  top: 10px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
}
.content-achievement .achievement-list a .icon svg {
  width: 18px;
  fill: #fff;
}
.content-achievement .achievement-list a figure img {
  width: 100%;
}
.content-achievement .achievement-list a .meta {
  background: #001976;
  color: #fff;
  height: 30px;
  padding: 0 15px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-achievement .achievement-list a .title {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 15px 15px 18px;
  line-height: 1.33;
}
@media only screen and (max-width: 768px) {
  .content-achievement .achievement-list {
    display: block;
    margin-top: 20px;
    padding: 0 20px;
  }
  .content-achievement .achievement-list a {
    display: block;
    background: #fff;
    color: #222;
    width: 100%;
    margin: 20px 0;
  }
  .content-achievement .achievement-list a .icon {
    background: #001976;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 10px;
    top: 10px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    border-radius: 40px;
  }
  .content-achievement .achievement-list a .icon svg {
    width: 18px;
    fill: #fff;
  }
  .content-achievement .achievement-list a .meta {
    height: 30px;
    padding: 0 15px 2px;
  }
  .content-achievement .achievement-list a .title {
    font-size: 1.6rem;
  }
}
.content-achievement .wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.content-achievement .wp-pagenavi span,
.content-achievement .wp-pagenavi a {
  color: #222;
  font-weight: bold;
  display: inline-block;
  margin: 0 4px;
  padding: 6px 8px;
  line-height: 1;
  font-size: 1.8rem;
  transition: all 0.2s;
}
.content-achievement .wp-pagenavi span:hover, .content-achievement .wp-pagenavi span.current,
.content-achievement .wp-pagenavi a:hover,
.content-achievement .wp-pagenavi a.current {
  color: #3A58C2;
  text-decoration: underline;
}
.content-achievement .wp-pagenavi span:hover.previouspostslink::after, .content-achievement .wp-pagenavi span.current.previouspostslink::after,
.content-achievement .wp-pagenavi a:hover.previouspostslink::after,
.content-achievement .wp-pagenavi a.current.previouspostslink::after {
  border-top: solid 3px #3A58C2;
  border-left: solid 3px #3A58C2;
}
.content-achievement .wp-pagenavi span:hover.nextpostslink::after, .content-achievement .wp-pagenavi span.current.nextpostslink::after,
.content-achievement .wp-pagenavi a:hover.nextpostslink::after,
.content-achievement .wp-pagenavi a.current.nextpostslink::after {
  border-top: solid 3px #3A58C2;
  border-right: solid 3px #3A58C2;
}
.content-achievement .wp-pagenavi span.previouspostslink, .content-achievement .wp-pagenavi span.nextpostslink,
.content-achievement .wp-pagenavi a.previouspostslink,
.content-achievement .wp-pagenavi a.nextpostslink {
  font-style: 0;
  position: relative;
  width: 30px;
  height: 30px;
  margin-top: 2px;
  font-size: 0;
}
.content-achievement .wp-pagenavi span.previouspostslink::after, .content-achievement .wp-pagenavi span.nextpostslink::after,
.content-achievement .wp-pagenavi a.previouspostslink::after,
.content-achievement .wp-pagenavi a.nextpostslink::after {
  content: "";
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  border-top: solid 3px #222;
  margin-top: -8px;
  transition: all 0.2s;
}
.content-achievement .wp-pagenavi span.previouspostslink,
.content-achievement .wp-pagenavi a.previouspostslink {
  margin-right: 20px;
}
.content-achievement .wp-pagenavi span.previouspostslink::after,
.content-achievement .wp-pagenavi a.previouspostslink::after {
  margin-left: 2px;
  border-left: solid 3px #222;
  transform: rotate(-45deg);
}
.content-achievement .wp-pagenavi span.nextpostslink,
.content-achievement .wp-pagenavi a.nextpostslink {
  margin-left: 20px;
}
.content-achievement .wp-pagenavi span.nextpostslink::after,
.content-achievement .wp-pagenavi a.nextpostslink::after {
  margin-left: -4px;
  border-right: solid 3px #222;
  transform: rotate(45deg);
}
.content-achievement .achievement-content {
  background: #fff;
  margin-bottom: 50px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.content-achievement .achievement-content .icon {
  background: #001976;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
  -webkit-border-radius: 64px;
  -moz-border-radius: 64px;
  -ms-border-radius: 64px;
  border-radius: 64px;
}
.content-achievement .achievement-content .icon .icon-in {
  text-align: center;
}
.content-achievement .achievement-content .icon .icon-in p {
  margin-top: 4px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1;
  font-family: "Roboto", sans-serif;
}
.content-achievement .achievement-content .icon svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
.content-achievement .achievement-content figure {
  text-align: center;
}
.content-achievement .achievement-content figure img {
  width: 100%;
}
.content-achievement .achievement-content figure img.noimage {
  max-width: 640px;
}
.content-achievement .achievement-content .meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #001976;
  color: #fff;
  height: 40px;
  padding: 0 20px 2px;
  font-size: 1.6rem;
}
.content-achievement .achievement-content .information {
  padding: 40px 80px 50px;
}
.content-achievement .achievement-content .information .title {
  color: #001976;
  margin-bottom: 30px;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.33;
  font-family: "Noto Serif JP", serif;
}
.content-achievement .achievement-content .information .item-list {
  margin-bottom: 40px;
  overflow: hidden;
  border-top: 1px solid #001976;
  border-bottom: 1px solid #001976;
}
.content-achievement .achievement-content .information .item-list li {
  display: table;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  overflow: hidden;
}
.content-achievement .achievement-content .information .item-list li:first-child {
  border-top: none;
}
.content-achievement .achievement-content .information .item-list .item {
  display: table-cell;
  width: 160px;
  font-weight: bold;
  padding-right: 15px;
}
.content-achievement .achievement-content .information .item-list .text {
  display: table-cell;
  vertical-align: middle;
}
.content-achievement .achievement-content .information .comment {
  font-size: 1.6rem;
  text-align: center;
  line-height: 2;
}
@media only screen and (max-width: 768px) {
  .content-achievement .achievement-content .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 10px;
    top: 10px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    border-radius: 40px;
  }
  .content-achievement .achievement-content .icon .icon-in {
    width: 18px;
    height: 18px;
  }
  .content-achievement .achievement-content .icon .icon-in p {
    display: none;
  }
  .content-achievement .achievement-content .icon svg {
    vertical-align: top;
    width: 18px;
    height: 18px;
  }
  .content-achievement .achievement-content .meta {
    height: 30px;
    padding: 0 20px 2px;
    font-size: 1.4rem;
  }
  .content-achievement .achievement-content .information {
    padding: 20px 20px 30px;
  }
  .content-achievement .achievement-content .information .title {
    margin-bottom: 20px;
    font-size: 2.2rem;
    text-align: left;
  }
  .content-achievement .achievement-content .information .item-list {
    margin-bottom: 20px;
  }
  .content-achievement .achievement-content .information .item-list .item {
    width: 100px;
  }
  .content-achievement .achievement-content .information .comment {
    font-size: 1.3rem;
    text-align: left;
  }
}

/* ----------------------------------------
 Page 固定ページ基本
---------------------------------------- */
.content-page h2 {
  text-align: left;
  padding-bottom: 0px;
  font-size: 2.8rem;
  margin-top: 40px;
  margin-bottom: 20px;
  border-left: solid 4px #001976;
  padding-left: 20px;
  font-weight: bold;
}
.content-page h2::after {
  display: none;
}
.content-page h3 {
  margin: 20px 0 10px;
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
}
.content-page a.fancybox {
  display: inline-block;
  margin-right: 5px;
  padding: 2px 5px;
  font-weight: bold;
  font-size: 1.8rem;
}

/* ========================================
 Puzzle LP Setting
======================================== */
body#page-puzzle header .nav-wrap .lp-nav-sp {
  display: none;
}
@media only screen and (max-width: 768px) {
  body#page-puzzle header .nav-wrap .lp-nav-sp {
    display: block;
    margin-left: 20px;
  }
  body#page-puzzle header .nav-wrap .lp-nav-sp a {
    display: inline-block;
    background: #3A58C2;
    color: #fff;
    line-height: 1;
    margin-right: 6px;
    padding: 0;
    font-size: 1.4rem;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
  }
  body#page-puzzle header .nav-wrap .lp-nav-sp a::after {
    display: none;
  }
  body#page-puzzle header .nav-wrap .lp-nav-sp a span {
    padding: 5px 12px 7px;
  }
}

.lum-lightbox {
  z-index: 9999;
}

.lp-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/puzzle/visual.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;
}
.lp-visual::after {
  content: "";
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.lp-visual .scroll {
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  bottom: 20px;
  z-index: 1;
  padding-top: 50px;
  margin-left: -18px;
  transition: all 0.2s;
}
.lp-visual .scroll span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 22px;
  height: 36px;
  margin-left: -11px;
  border: 2px solid #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 1.1rem;
  transition: all 0.2s;
}
.lp-visual .scroll span:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 50%;
  width: 4px;
  height: 4px;
  margin-left: -2px;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: sdb 2s infinite;
  animation: sdb 2s infinite;
  box-sizing: border-box;
  transition: all 0.2s;
}
.lp-visual .scroll span:after {
  content: "";
  position: absolute;
  top: 34px;
  left: 50%;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
  transition: all 0.2s;
}
@-webkit-keyframes sdb {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 18px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 18px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.lp-visual h1 {
  background: rgba(0, 0, 0, 0.75);
  padding: 50px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.lp-visual h1 .inc-logo {
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 40px;
}
.lp-visual h1 .inc-logo:after {
  content: "";
  background-color: #FFCC00;
  width: 120px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -60px;
}
.lp-visual h1 .inc-logo svg {
  fill: #fff;
}
.lp-visual h1 .text {
  color: #fff;
  font-family: "Noto Serif JP", serif;
  font-size: 4.6rem;
}
@media only screen and (max-width: 768px) {
  .lp-visual {
    height: 100%;
    padding: 20px;
    background-image: url(../img/puzzle/visual_sp.png);
  }
  .lp-visual h1 {
    background: rgba(0, 0, 0, 0.75);
    padding: 40px 20px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .lp-visual h1 .inc-logo {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  .lp-visual h1 .inc-logo svg {
    width: 240px;
    height: 68.98px;
  }
  .lp-visual h1 .inc-logo:after {
    content: "";
    background-color: #FFCC00;
    width: 64px;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -32px;
  }
  .lp-visual h1 .text {
    color: #fff;
    font-family: "Noto Serif JP", serif;
    font-size: 1.6rem;
  }
}

#content-wrapper.is-puzzle {
  margin-bottom: 100px;
}
@media only screen and (max-width: 768px) {
  #content-wrapper.is-puzzle {
    margin-bottom: 50px;
  }
}
#content-wrapper.is-puzzle .message {
  margin-top: 50px;
  color: #001976;
  font-size: 2.6rem;
  font-weight: bold;
  text-align: center;
}
#content-wrapper.is-puzzle h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #02173D;
  color: #fff;
  height: 150px;
  margin: 0;
  padding: 0 20px;
}
#content-wrapper.is-puzzle h2 .h2-in {
  font-family: "Noto Serif JP", serif;
  margin-bottom: 15px;
  padding-bottom: 30px;
  font-size: 3.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  position: relative;
}
#content-wrapper.is-puzzle h2 .h2-in:after {
  content: "";
  background-color: #FFCC00;
  width: 64px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -32px;
}
#content-wrapper.is-puzzle .content-inner {
  padding-top: 50px;
  padding-bottom: 100px;
}
#content-wrapper.is-puzzle.lp-navFixed .lp-nav .lp-nav-in {
  position: fixed;
  left: 0;
  top: 100px;
  z-index: 9999;
}
@media only screen and (max-width: 768px) {
  #content-wrapper.is-puzzle .message {
    margin-top: 30px;
    font-size: 1.8rem;
    text-align: left;
  }
  #content-wrapper.is-puzzle h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #02173D;
    color: #fff;
    height: 150px;
    margin: 0;
    padding: 0 20px;
  }
  #content-wrapper.is-puzzle h2 .h2-in {
    font-family: "Noto Serif JP", serif;
    margin-bottom: 10px;
    padding-bottom: 20px;
    font-size: 2.4rem;
    font-weight: bold;
    letter-spacing: 0.1em;
    text-align: center;
    position: relative;
  }
  #content-wrapper.is-puzzle h2 .h2-in:after {
    content: "";
    background-color: #FFCC00;
    width: 64px;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -32px;
  }
  #content-wrapper.is-puzzle .content-inner {
    padding-top: 25px;
    padding-bottom: 50px;
  }
}

.lp-nav {
  background: #02173D;
  width: 100%;
  height: 54px;
}
.lp-nav-in {
  background: #02173D;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.lp-nav a {
  display: block;
  background: #fff;
  color: #001976;
  min-width: 82px;
  margin: 0 5px;
  padding: 6px 10px;
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold;
  border-bottom: 2px solid #FFCC00;
  -webkit-border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  border-top-right-radius: 4px;
  transition: all 0.2s;
}
body:not(.smp) .lp-nav a:hover {
  background: #3A58C2;
  color: #fff;
}
body:not(.smp) .lp-nav a:hover span::before {
  border-top: 7px solid #fff;
}
.lp-nav a span {
  padding-left: 14px;
  position: relative;
}
.lp-nav a span::before {
  content: "";
  display: inline-block;
  border-top: 7px solid #001976;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  transition: all 0.2s;
}
@media only screen and (max-width: 960px) {
  .lp-nav a {
    padding: 6px 4px 8px;
    font-size: 1.2rem;
    letter-spacing: 0;
  }
  .lp-nav a span {
    padding-left: 10px;
  }
  .lp-nav a span::before {
    border-top: 6px solid #001976;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-top: -3px;
  }
}

.lp-comment {
  text-align: center;
  margin-bottom: 50px;
}
.lp-comment .lp-comment-in {
  background: #fff;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  min-width: 500px;
  padding: 20px 20px 24px;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  border: solid 3px #001976;
  position: relative;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.lp-comment .lp-comment-in::before, .lp-comment .lp-comment-in::after {
  content: "";
  position: absolute;
}
.lp-comment .lp-comment-in::before {
  bottom: -24px;
  left: 50%;
  margin-left: -15px;
  border: 12px solid transparent;
  border-top: 12px solid #FFF;
  z-index: 2;
}
.lp-comment .lp-comment-in::after {
  bottom: -30px;
  left: 50%;
  margin-left: -17px;
  border: 14px solid transparent;
  border-top: 14px solid #001976;
  z-index: 1;
}
.lp-comment .lp-comment-in span {
  color: #3A58C2;
}
@media only screen and (max-width: 768px) {
  .lp-comment {
    margin-bottom: 30px;
  }
  .lp-comment .lp-comment-in {
    min-width: auto;
    padding: 20px 15px 24px;
    font-size: 1.4rem;
  }
}

.lp-init {
  background: #eee;
}
.lp-init__in {
  background: #fff;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 7px;
  border: solid #02173D 3px;
  position: relative;
}
.lp-init__in .title {
  display: flex;
  align-items: center;
  background: #02173D;
  color: #fff;
  width: 100%;
  height: 200px;
  padding-left: 70px;
  font-size: 3.8rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}
.lp-init__in .title .text {
  position: relative;
  z-index: 1;
}
.lp-init__in .title .text span {
  color: #F8D347;
}
.lp-init__in .title .icon {
  position: absolute;
  right: 0;
  top: auto;
  transform: rotate(-15deg);
}
.lp-init__in .note-wrap {
  padding: 24px 70px 60px;
}
.lp-init__in .note-wrap .note {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, #fff 50%, #fff 50%), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 97%, #ccc 97%, #ccc 100%);
  background-size: 4px 100%, 100% 2em;
  padding-top: 2em;
  line-height: 2;
  font-size: 1.8rem;
  letter-spacing: 0;
}
.lp-init__in .note-wrap .note .bold {
  color: #001976;
  font-weight: bold;
}
@media only screen and (max-width: 1000px) {
  .lp-init__in {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .lp-init__in .title {
    display: flex;
    align-items: center;
    background: #02173D;
    color: #fff;
    width: 100%;
    height: 150px;
    padding-left: 20px;
    font-size: 1.8rem;
    letter-spacing: 0.1em;
  }
  .lp-init__in .title .icon svg {
    width: 150px;
    height: 150px;
  }
  .lp-init__in .note-wrap {
    padding: 4px 20px 30px;
  }
  .lp-init__in .note-wrap .note {
    background-size: 4px 100%, 100% 2em;
    padding-top: 2em;
    line-height: 2;
    font-size: 1.3rem;
    letter-spacing: 0;
  }
  .lp-init__in .note-wrap .note .bold {
    color: #001976;
    font-weight: bold;
  }
}

.lp-type-list {
  border-top: 2px solid #02173D;
}
.lp-type-list li {
  padding: 30px 0;
  min-height: 310px;
  font-size: 1.6rem;
  position: relative;
  border-bottom: 2px solid #02173D;
}
.lp-type-list li h3 {
  color: #001976;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  padding-bottom: 10px;
  border-bottom: 2px dotted #ccc;
}
.lp-type-list li figure {
  width: 25%;
  position: absolute;
  right: 0;
  top: 30px;
}
.lp-type-list li figure img {
  width: 100%;
  border: #ccc solid 2px;
}
.lp-type-list li figure a {
  color: #001976;
  font-size: 1.5rem;
  text-align: center;
}
.lp-type-list li figure a .click {
  margin-top: 10px;
}
.lp-type-list li figure a .click svg {
  margin-right: 0.5em;
}
.lp-type-list li .information {
  width: 71.875%;
}
.lp-type-list li .information p {
  margin-top: 20px;
  line-height: 1.75;
  font-weight: bold;
}
.lp-type-list li .information .point {
  margin-top: 20px;
  color: #3A58C2;
  overflow: hidden;
}
.lp-type-list li .information .point dt {
  float: left;
  margin-right: 0.5em;
}
.lp-type-list li .information .point dd {
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .lp-type-list li {
    padding: 20px 0;
    min-height: none;
    font-size: 1.4rem;
  }
  .lp-type-list li h3 {
    color: #001976;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 10px;
    border-bottom: 2px dotted #ccc;
  }
  .lp-type-list li figure {
    width: 64%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }
  .lp-type-list li figure img {
    width: 100%;
    border: #ccc solid 2px;
  }
  .lp-type-list li figure a {
    color: #001976;
    font-size: 1.3rem;
    text-align: center;
  }
  .lp-type-list li figure a .click {
    margin-top: 5px;
  }
  .lp-type-list li figure a .click svg {
    margin-right: 0.5em;
  }
  .lp-type-list li .information {
    width: 100%;
  }
  .lp-type-list li .information p {
    margin-top: 20px;
    line-height: 1.75;
    font-weight: bold;
  }
  .lp-type-list li .information .point {
    margin-top: 20px;
    color: #3A58C2;
    overflow: hidden;
  }
  .lp-type-list li .information .point dt {
    float: left;
    margin-right: 0.5em;
  }
  .lp-type-list li .information .point dd {
    overflow: hidden;
  }
}

.lp-step {
  background: #eee;
}
.lp-step .step-list .step-set {
  display: flex;
}
.lp-step .step-list .step-set .step-one {
  width: calc(33.33% - 9px);
}
.lp-step .step-list .step-set .step-one:nth-of-type(1) {
  z-index: 3;
}
.lp-step .step-list .step-set .step-one:nth-of-type(2) {
  z-index: 2;
}
.lp-step .step-list .step-set .step-one:nth-of-type(2) .text {
  padding-left: 10px;
}
.lp-step .step-list .step-set .step-one:nth-of-type(2) h3 .h3-in {
  padding-left: 30px;
}
.lp-step .step-list .step-set .step-one:nth-of-type(3) {
  z-index: 1;
}
.lp-step .step-list .step-set .step-one:nth-of-type(3) .text {
  padding-left: 10px;
}
.lp-step .step-list .step-set .step-one:nth-of-type(3) h3 .h3-in {
  padding-left: 30px;
}
.lp-step .step-list .step-set .step-one_info {
  min-height: auto;
}
.lp-step .step-list .step-one {
  position: relative;
  width: calc(100% - 28px);
  margin-bottom: 40px;
}
.lp-step .step-list .step-one .text {
  font-size: 1.8rem;
  font-weight: bold;
}
.lp-step .step-list .step-one figure {
  text-align: center;
}
.lp-step .step-list .step-one_info {
  padding: 20px;
  position: relative;
  min-height: 176px;
}
.lp-step .step-list .step-one_info figure {
  position: absolute;
  right: 20px;
  top: 20px;
}
.lp-step .step-list .step-one_info-left {
  width: calc(100% - 190px);
}
.lp-step .step-list .step-one_info-left .subtext {
  margin-top: 20px;
  font-size: 1.6rem;
}
.lp-step .step-list .step-one_info-left ul {
  margin-top: 20px;
  font-size: 1.6rem;
  border-top: dotted 1px #222;
}
.lp-step .step-list .step-one_info-left ul li {
  overflow: hidden;
  padding: 10px 0;
  border-bottom: dotted 1px #222;
}
.lp-step .step-list .step-one_info-left ul li p {
  float: left;
  width: 220px;
  color: #001976;
  font-weight: bold;
}
.lp-step .step-list .step-one_info-left ul li span {
  display: block;
  padding-left: 20px;
  overflow: hidden;
}
.lp-step .step-list h3 {
  background: #001976;
  color: #fff;
  width: 100%;
  height: 56px;
  position: relative;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  font-size: 2.5rem;
  font-weight: bold;
}
.lp-step .step-list h3 span {
  display: inline-block;
  padding-left: 5px;
  font-size: 4rem;
}
.lp-step .step-list h3::before, .lp-step .step-list h3::after {
  position: absolute;
  margin: auto;
  content: "";
  vertical-align: middle;
  z-index: 0;
}
.lp-step .step-list h3::before {
  top: 0;
  right: -5px;
  width: 5px;
  height: 56px;
  background: #eee;
}
.lp-step .step-list h3::after {
  right: -45px;
  top: 0;
  box-sizing: border-box;
  width: 10px;
  height: 56px;
  border: 28px solid transparent;
  border-left: 12px solid #eee;
}
.lp-step .step-list h3 .h3-in {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}
.lp-step .step-list h3 .h3-in::after {
  content: "";
  position: absolute;
  right: -40px;
  top: 0;
  box-sizing: border-box;
  width: 10px;
  height: 56px;
  border: 28px solid transparent;
  border-left: 12px solid #001976;
  z-index: 1;
}
.lp-step .step-bottom {
  background: #fff;
  width: 100%;
  max-width: 640px;
  margin: 20px auto;
  padding: 30px 15px;
  border: solid 2px #001976;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
}
.lp-step .step-bottom .title {
  color: #001976;
  font-size: 2.6rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.lp-step .step-bottom .text {
  font-size: 1.6rem;
}
.lp-step .step-bottom .btn-area {
  margin-top: 20px;
}
.lp-step .step-bottom .btn-area a {
  background: #001976;
  color: #fff;
  width: 280px;
}
body:not(.smp) .lp-step .step-bottom .btn-area a:hover {
  background: #FFCC00;
  color: #001976;
}
body:not(.smp) .lp-step .step-bottom .btn-area a:hover svg {
  fill: #001976;
}
.lp-step .step-bottom .btn-area a svg {
  fill: #fff;
}
@media only screen and (max-width: 768px) {
  .lp-step .step-list .step-set {
    display: block;
  }
  .lp-step .step-list .step-set .step-one {
    width: calc(100% - 9px);
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(1) {
    z-index: 3;
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(2) {
    z-index: 2;
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(2) .text {
    padding-left: 0px;
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(2) h3 .h3-in {
    padding-left: 10px;
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(3) {
    z-index: 1;
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(3) .text {
    padding-left: 0px;
  }
  .lp-step .step-list .step-set .step-one:nth-of-type(3) h3 .h3-in {
    padding-left: 10px;
  }
  .lp-step .step-list .step-set .step-one_info {
    min-height: auto;
  }
  .lp-step .step-list .step-one {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .lp-step .step-list .step-one .text {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .lp-step .step-list .step-one figure {
    text-align: center;
  }
  .lp-step .step-list .step-one figure img {
    width: auto;
    max-width: 100%;
  }
  .lp-step .step-list .step-one_info {
    padding: 15px 10px;
    position: relative;
    min-height: auto;
  }
  .lp-step .step-list .step-one_info figure {
    position: static;
  }
  .lp-step .step-list .step-one_info-left {
    width: 100%;
  }
  .lp-step .step-list .step-one_info-left .subtext {
    margin-top: 20px;
    font-size: 1.6rem;
  }
  .lp-step .step-list .step-one_info-left ul {
    margin-top: 20px;
    font-size: 1.4rem;
    border-top: dotted 1px #222;
  }
  .lp-step .step-list .step-one_info-left ul li {
    overflow: hidden;
    padding: 10px 0;
    border-bottom: dotted 1px #222;
  }
  .lp-step .step-list .step-one_info-left ul li p {
    float: none;
    width: auto;
    color: #001976;
    font-weight: bold;
  }
  .lp-step .step-list .step-one_info-left ul li span {
    display: block;
    padding-left: 0px;
    overflow: hidden;
  }
  .lp-step .step-list h3 {
    background: #001976;
    color: #fff;
    width: calc(100% - 10px);
    height: 40px;
    position: relative;
    font-family: "Roboto", sans-serif;
    line-height: 1;
    font-size: 2rem;
    font-weight: bold;
  }
  .lp-step .step-list h3 span {
    display: inline-block;
    padding-left: 5px;
    font-size: 3rem;
  }
  .lp-step .step-list h3::before, .lp-step .step-list h3::after {
    position: absolute;
    margin: auto;
    content: "";
    vertical-align: middle;
    z-index: 0;
  }
  .lp-step .step-list h3::before {
    top: 0;
    right: -5px;
    width: 5px;
    height: 40px;
    background: #eee;
  }
  .lp-step .step-list h3::after {
    right: -45px;
    top: 0;
    box-sizing: border-box;
    width: 10px;
    height: 40px;
    border: 28px solid transparent;
    border-left: 12px solid #eee;
  }
  .lp-step .step-list h3 .h3-in {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
  }
  .lp-step .step-list h3 .h3-in::after {
    content: "";
    position: absolute;
    right: -28px;
    top: 0;
    box-sizing: border-box;
    width: 10px;
    height: 40px;
    border: 20px solid transparent;
    border-left: 8px solid #001976;
    z-index: 1;
  }
  .lp-step .step-bottom {
    margin: 20px auto;
    padding: 20px 15px 25px;
  }
  .lp-step .step-bottom .title {
    color: #001976;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .lp-step .step-bottom .text {
    font-size: 1.3rem;
  }
}

.lp-results .content-width {
  max-width: 900px;
}
.lp-results .results-lead {
  text-align: center;
}
.lp-results .results-main {
  display: flex;
  justify-content: sb;
  margin-top: 50px;
}
.lp-results .results-main li {
  width: 50%;
  padding-left: 5.5555555556%;
}
.lp-results .results-main li:first-child {
  padding-left: 0;
  padding-right: 5.5555555556%;
  border-right: dotted 1px #ccc;
}
.lp-results .results-main li .title {
  font-size: 3rem;
  color: #001976;
  font-weight: bold;
  margin-bottom: 20px;
}
.lp-results .results-main li .text {
  font-size: 1.6rem;
}
.lp-results .results-main li figure {
  max-width: 180px;
  margin-top: 20px;
  text-align: center;
}
.lp-results .results-main li figure img {
  width: 100%;
  border: #ccc solid 2px;
}
@media only screen and (max-width: 768px) {
  .lp-results .results-main {
    display: block;
  }
  .lp-results .results-main li {
    width: 100%;
    padding-left: 0;
  }
  .lp-results .results-main li:first-child {
    padding-left: 0;
    padding-right: 0;
    border-right: none;
    border-bottom: dotted 1px #ccc;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .lp-results .results-main li .title {
    font-size: 2rem;
  }
  .lp-results .results-main li .text {
    font-size: 1.3rem;
  }
  .lp-results .results-main li figure {
    margin-left: auto;
    margin-right: auto;
  }
}

.lp-price .price-main li {
  padding: 30px 40px 40px;
  border: solid 3px #ccc;
}
.lp-price .price-main li:first-child {
  margin-bottom: 40px;
}
.lp-price .price-main li .title {
  background: #001976;
  padding: 16px;
  font-size: 2rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
  line-height: 1;
}
.lp-price .price-main li .text p {
  margin: 30px 0;
  font-size: 2.8rem;
  text-align: center;
  font-weight: bold;
  text-align: center;
}
.lp-price .price-main li .text p span {
  display: inline-block;
  padding-bottom: 2px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 95%, #FFCC00 95%, #FFCC00 100%);
  background-size: 100% 2em;
  line-height: 2;
}
.lp-price .price-main li .simple-price {
  overflow: hidden;
  margin: 30px 0;
}
.lp-price .price-main li .simple-price dt, .lp-price .price-main li .simple-price dd {
  float: left;
  width: 50%;
  color: #001976;
  font-size: 2rem;
}
.lp-price .price-main li .simple-price dt {
  text-align: right;
}
.lp-price .price-main li .simple-price dd {
  font-weight: bold;
}
.lp-price .price-main li .price-table {
  margin-top: 20px;
  border-collapse: collapse;
  font-size: 1.6rem;
}
.lp-price .price-main li .price-table tr .t1 {
  width: 34.0909090909%;
}
.lp-price .price-main li .price-table tr .t4 {
  width: 25%;
}
.lp-price .price-main li .price-table tr th, .lp-price .price-main li .price-table tr td {
  padding: 5px;
  border: solid 1px #001976;
}
.lp-price .price-main li .price-table tr th {
  background: #3A58C2;
  color: #fff;
  text-align: left;
}
.lp-price .price-main li .price-table tr th:nth-of-type(1) {
  padding-left: 10px;
}
.lp-price .price-main li .price-table tr td {
  text-align: center;
  font-weight: bold;
}
.lp-price .price-main li .price-table tr td:nth-of-type(1) {
  font-weight: normal;
  text-align: left;
  padding-left: 10px;
}
.lp-price .price-main li .price-table tr td:nth-of-type(1).tac {
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .lp-price .price-main li {
    padding: 10px 10px 20px;
    border: solid 2px #ccc;
  }
  .lp-price .price-main li:first-child {
    margin-bottom: 30px;
  }
  .lp-price .price-main li .title {
    background: #001976;
    padding: 12px;
    font-size: 1.6rem;
  }
  .lp-price .price-main li .text {
    padding: 0 10px;
  }
  .lp-price .price-main li .text p {
    margin: 15px 0;
    font-size: 2rem;
  }
  .lp-price .price-main li .simple-price {
    overflow: hidden;
    margin: 20px 0;
    padding: 0 10px;
  }
  .lp-price .price-main li .simple-price dt, .lp-price .price-main li .simple-price dd {
    float: none;
    width: 100%;
    color: #001976;
    font-size: 1.3rem;
  }
  .lp-price .price-main li .simple-price dt {
    text-align: left;
  }
  .lp-price .price-main li .simple-price dd {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .lp-price .price-main li .price-table {
    margin-top: 20px;
    border-collapse: collapse;
    font-size: 1.2rem;
  }
  .lp-price .price-main li .price-table tr .t1 {
    width: 34.0909090909%;
  }
  .lp-price .price-main li .price-table tr .t4 {
    width: 25%;
  }
  .lp-price .price-main li .price-table tr th, .lp-price .price-main li .price-table tr td {
    padding: 5px 3px;
    border: solid 1px #001976;
  }
  .lp-price .price-main li .price-table tr th:nth-of-type(1) {
    padding-left: 5px;
  }
  .lp-price .price-main li .price-table tr td:nth-of-type(1) {
    padding-left: 5px;
  }
}

.lp-faq .content-width {
  max-width: 900px;
}
.lp-faq .faq-list li {
  margin-top: 40px;
  line-height: 1.5;
}
.lp-faq .faq-list li dl {
  overflow: hidden;
}
.lp-faq .faq-list li dl.q {
  color: #001976;
  border-bottom: 1px dashed #070707;
  padding-bottom: 5px;
}
.lp-faq .faq-list li dl.q dd {
  font-size: 2.6rem;
  font-weight: bold;
}
.lp-faq .faq-list li dl.a dt {
  color: #3A58C2;
}
.lp-faq .faq-list li dl.a dd {
  padding-top: 0.5em;
}
.lp-faq .faq-list li dl dt {
  float: left;
  margin-right: 10px;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
.lp-faq .faq-list li dl dd {
  font-size: 1.8rem;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .lp-faq .faq-list li {
    margin-top: 30px;
  }
  .lp-faq .faq-list li dl.q dd {
    font-size: 1.8rem;
  }
  .lp-faq .faq-list li dl.a dd {
    padding-top: 0.5em;
  }
  .lp-faq .faq-list li dl dt {
    font-size: 2rem;
  }
  .lp-faq .faq-list li dl dd {
    font-size: 1.3rem;
  }
}

.lp-voice .content-width {
  max-width: 900px;
}
.lp-voice .voice-list li {
  border-top: 1px dashed #070707;
  padding: 20px 0 10px;
}
.lp-voice .voice-list li:first-child {
  border-top: none;
}
.lp-voice .voice-list li .title {
  color: #001976;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.lp-voice .voice-list li p {
  margin: 20px 0;
  font-size: 1.6rem;
}
.lp-voice .voice-list li figure img {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .lp-voice .voice-list li {
    padding: 20px 0 10px;
  }
  .lp-voice .voice-list li .title {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }
  .lp-voice .voice-list li p {
    margin: 10px 0;
    font-size: 1.4rem;
  }
  .lp-voice .voice-list li figure img {
    width: 100%;
  }
}

.lp-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/puzzle/message_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;
}
.lp-message::after {
  content: "";
  background: rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
.lp-message .message-main {
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 50px;
  text-align: center;
  z-index: 1;
}
.lp-message .message-main .title {
  font-size: 4.8rem;
  line-height: 1;
  padding-bottom: 40px;
  font-family: "Noto Serif JP", serif;
  position: relative;
}
.lp-message .message-main .title:after {
  content: "";
  background-color: #FFCC00;
  width: 120px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -60px;
}
.lp-message .message-main p {
  font-size: 1.8rem;
  margin-top: 30px;
}
.lp-message .message-main .btn-area {
  margin-top: 50px;
}
.lp-message .message-main .btn-area a.is-big::after {
  display: none;
}
.lp-message .message-main .btn-area a .btn-in {
  padding-left: 30px;
}
.lp-message .message-main .btn-area a .icon_mail_dims {
  width: 24px;
  height: 16.82px;
  margin-top: -8.4px;
}
@media only screen and (max-width: 768px) {
  .lp-message {
    height: 600px;
    padding: 0 20px;
    position: relative;
  }
  .lp-message .message-main {
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 30px;
    text-align: center;
    z-index: 1;
  }
  .lp-message .message-main .title {
    font-size: 3rem;
    line-height: 1;
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-family: "Noto Serif JP", serif;
    position: relative;
  }
  .lp-message .message-main .title:after {
    content: "";
    background-color: #FFCC00;
    width: 64px;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -32px;
  }
  .lp-message .message-main p {
    font-size: 1.4rem;
    margin-top: 3px;
  }
  .lp-message .message-main .btn-area {
    margin-top: 30px;
  }
  .lp-message .message-main .btn-area a.is-big::after {
    display: none;
  }
  .lp-message .message-main .btn-area a .btn-in {
    padding-left: 30px;
  }
  .lp-message .message-main .btn-area a .icon_mail_dims {
    width: 24px;
    height: 16.82px;
    margin-top: -8.4px;
  }
}
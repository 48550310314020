/* ========================================
 Puzzle LP Setting
======================================== */
body#page-puzzle {
  header {
    .nav-wrap {
      .lp-nav-sp {
        display: none;
        @media only screen and (max-width: $breakpoint) {
          display: block;
          margin-left: 20px;
          a {
            display: inline-block;
            background: $c-blue;
            color: $c-white;
            line-height: 1;
            margin-right: 6px;
            padding: 0;
            font-size: 1.4rem;
            @include border-radius(5px);
            &::after {
              display: none;
            }
            span {
              padding: 5px 12px 7px;
            }
          }
        }
      }
    }
  }
}
.lum-lightbox {
  z-index: 9999;
}
.lp-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/puzzle/visual.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;
  &::after {
    content:"";
    background: rgba(0,0,0,0.64);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .scroll {
    color: $c-white;
    font-size: 1.2rem;
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 1;
    padding-top: 50px;
    margin-left: -18px;
    transition: all 0.2s;
    span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 22px;
      height: 36px;
      margin-left: -11px;
      border: 2px solid $c-white;
      @include border-radius(10px);
      box-sizing: border-box;
      font-size: 1.1rem;
      transition: all 0.2s;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 50%;
        width: 4px;
        height: 4px;
        margin-left: -2px;
        background-color: $c-white;
        @include border-radius(100%);
        -webkit-animation: sdb 2s infinite;
        animation: sdb 2s infinite;
        box-sizing: border-box;
        transition: all 0.2s;
      }
      &:after {
        content: '';
        position: absolute;
        top: 34px;
        left: 50%;
        width: 12px;
        height: 12px;
        margin-left: -6px;
        border-left: 1px solid $c-white;
        border-bottom: 1px solid $c-white;
        transform: rotate(-45deg);
        transition: all 0.2s;
      }
    }
  }
  @-webkit-keyframes sdb {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 18px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 18px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  h1 {
    background: rgba(#000,0.75);
    padding: 50px;
    text-align: center;
    position: relative;
    z-index: 1;
    .inc-logo {
      position: relative;
      padding-bottom: 50px;
      margin-bottom: 40px;
      &:after {
        content:"";
        background-color: $c-yellow;
        width: 120px;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -60px;
      }
      svg {
        fill: #fff;
      }
    }
    .text {
      color: #fff;
      @include mincho;
      font-size: 4.6rem;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    height: 100%;
    padding: 20px;
    background-image: url(../img/puzzle/visual_sp.png);
    h1 {
      background: rgba(#000,0.75);
      padding: 40px 20px;
      text-align: center;
      position: relative;
      z-index: 1;
      .inc-logo {
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
        svg {
          width: 240px;
          height: 68.98px;
        }
        &:after {
          content:"";
          background-color: $c-yellow;
          width: 64px;
          height: 1px;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -32px;
        }
      }
      .text {
        color: #fff;
        @include mincho;
        font-size: 1.6rem;
      }
    }
  }
}
#content-wrapper.is-puzzle {
  margin-bottom: 100px;
  @media only screen and (max-width: $breakpoint) {
    margin-bottom: 50px;
  }
  .message {
    margin-top: 50px;
    color: $c-main;
    font-size: 2.6rem;
    font-weight: bold;
    text-align: center;
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $c-darkblue;
    color: $c-white;
    height: 150px;
    margin: 0;
    padding: 0 20px;
    .h2-in {
      @include mincho;
      margin-bottom: 15px;
      padding-bottom: 30px;
      font-size: 3.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      position: relative;
      &:after {
        content:"";
        background-color: $c-yellow;
        width: 64px;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -32px;
      }
    }
  }
  .content-inner {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  &.lp-navFixed {
    .lp-nav {
      .lp-nav-in {
        position: fixed;
        left: 0;
        top: 100px;
        z-index: 9999;
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .message {
      margin-top: 30px;
      font-size: 1.8rem;
      text-align: left;
    }
    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $c-darkblue;
      color: $c-white;
      height: 150px;
      margin: 0;
      padding: 0 20px;
      .h2-in {
        @include mincho;
        margin-bottom: 10px;
        padding-bottom: 20px;
        font-size: 2.4rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        text-align: center;
        position: relative;
        &:after {
          content:"";
          background-color: $c-yellow;
          width: 64px;
          height: 1px;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -32px;
        }
      }
    }
    .content-inner {
      padding-top: 25px;
      padding-bottom: 50px;
    }
  }
}
.lp-nav {
  background: $c-darkblue;
  width: 100%;
  height: 54px;
  &-in {
    background: $c-darkblue;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items:flex-end;
  }
  a {
    display: block;
    background: $c-white;
    color: $c-main;
    min-width: 82px;
    margin: 0 5px;
    padding: 6px 10px;
    font-size: 1.4rem;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid $c-yellow;
    @include border-top-left-radius(4px);
    @include border-top-right-radius(4px);
    transition: all 0.2s;
    body:not(.smp) &:hover {
      background: $c-blue;
      color: $c-white;
      span {
        &::before {
          border-top: 7px solid $c-white;
        }
      }
    }
    span {
      padding-left: 14px;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        border-top: 7px solid $c-main;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -3px;
        transition: all 0.2s;
      }
    }
  }
  @media only screen and (max-width: $pc-width) {
    a {
      padding: 6px 4px 8px;
      font-size: 1.2rem;
      letter-spacing: 0;
      span {
        padding-left: 10px;
        &::before {
          border-top: 6px solid $c-main;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
          margin-top: -3px;
        }
      }
    }
  }
}
.lp-comment {
  text-align: center;
  margin-bottom: 50px;
  .lp-comment-in {
    background: $c-white;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    min-width: 500px;
    padding: 20px 20px 24px;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    border: solid 3px $c-main;
    position: relative;
    @include border-radius(10px);
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      bottom: -24px;
      left: 50%;
      margin-left: -15px;
      border: 12px solid transparent;
      border-top: 12px solid #FFF;
      z-index: 2;
    }
    &::after {
      bottom: -30px;
      left: 50%;
      margin-left: -17px;
      border: 14px solid transparent;
      border-top: 14px solid $c-main;
      z-index: 1;
    }
    span {
      color: $c-blue;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    margin-bottom: 30px;
    .lp-comment-in {
      min-width: auto;
      padding: 20px 15px 24px;
      font-size: 1.4rem;
    }
  }
}
.lp-init {
  background: $c-gray;
  &__in {
    background: $c-white;
    width: 100%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: 7px;
    border: solid $c-darkblue 3px;
    position: relative;
    .title {
      display: flex;
      align-items: center;
      background: $c-darkblue;
      color: $c-white;
      width: 100%;
      height: 200px;
      padding-left: 70px;
      font-size: 3.8rem;
      letter-spacing: 0.1em;
      font-weight: bold;
      position: relative;
      overflow: hidden;
      .text {
        position: relative;
        z-index: 1;
        span {
          color: #F8D347;
        }
      }
      .icon {
        position: absolute;
        right: 0;
        top: auto;
        transform: rotate(-15deg);
      }
    }
    .note-wrap {
      padding: 24px 70px 60px;
      .note {
        background-image:linear-gradient(
          90deg,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) 50%,
          #fff 50%,
          #fff 50%
        ),
        linear-gradient(
          180deg,
          rgba(0,0,0,0) 0%,
          rgba(0,0,0,0) 97%,
          #ccc 97%,
          #ccc 100%
        );
        background-size: 4px 100%,100% 2em;
        padding-top: 2em;
        line-height:2;
        font-size: 1.8rem;
        letter-spacing: 0;
        .bold {
          color: $c-main;
          font-weight: bold;
        }
      }
    }
  }
  @media only screen and (max-width: 1000px) {
    &__in {
      width: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    &__in {
      .title {
        display: flex;
        align-items: center;
        background: $c-darkblue;
        color: $c-white;
        width: 100%;
        height: 150px;
        padding-left: 20px;
        font-size: 1.8rem;
        letter-spacing: 0.1em;
        .icon {
          svg {
            width: 150px;
            height: 150px;
          }
        }
      }
      .note-wrap {
        padding: 4px 20px 30px;
        .note {
          background-size: 4px 100%,100% 2em;
          padding-top: 2em;
          line-height:2;
          font-size: 1.3rem;
          letter-spacing: 0;
          .bold {
            color: $c-main;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.lp-type-list {
  border-top: 2px solid $c-darkblue;
  li {
    padding: 30px 0;
    min-height: 310px;
    font-size: 1.6rem;
    position: relative;
    border-bottom: 2px solid $c-darkblue;
    h3 {
      color: $c-main;
      font-size: 3rem;
      font-weight: bold;
      line-height: 1;
      padding-bottom: 10px;
      border-bottom: 2px dotted #ccc;
    }
    figure {
      width: calc((240/960)*100%);
      position: absolute;
      right: 0;
      top: 30px;
      img {
        width: 100%;
        border: #ccc solid 2px;
      }
      a {
        color: $c-main;
        font-size: 1.5rem;
        text-align: center;
        .click {
          margin-top: 10px;
          svg {
            margin-right: 0.5em;
          }
        }
      }
    }
    .information {
      width: calc((690/960)*100%);
      p {
        margin-top: 20px;
        line-height: 1.75;
        font-weight: bold;
      }
      .point {
        margin-top: 20px;
        color: $c-blue;
        overflow: hidden;
        dt {
          float: left;
          margin-right: 0.5em;
        }
        dd {
          overflow: hidden;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    li {
      padding: 20px 0;
      min-height: none;
      font-size: 1.4rem;
      h3 {
        color: $c-main;
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1;
        padding-bottom: 10px;
        border-bottom: 2px dotted #ccc;
      }
      figure {
        width: 64%;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        position: static;
        img {
          width: 100%;
          border: #ccc solid 2px;
        }
        a {
          color: $c-main;
          font-size: 1.3rem;
          text-align: center;
          .click {
            margin-top: 5px;
            svg {
              margin-right: 0.5em;
            }
          }
        }
      }
      .information {
        width: 100%;
        p {
          margin-top: 20px;
          line-height: 1.75;
          font-weight: bold;
        }
        .point {
          margin-top: 20px;
          color: $c-blue;
          overflow: hidden;
          dt {
            float: left;
            margin-right: 0.5em;
          }
          dd {
            overflow: hidden;
          }
        }
      }
    }
  }
}
.lp-step {
  background: $c-gray;
  .step-list {
    .step-set {
      display: flex;
      .step-one {
        width: calc(33.33% - 9px);
        &:nth-of-type(1) {z-index: 3;}
        &:nth-of-type(2) {
          z-index: 2;
          .text {
            padding-left: 10px;
          }
          h3 .h3-in {padding-left:30px;}
        }
        &:nth-of-type(3) {
          z-index: 1;
          .text {
            padding-left: 10px;
          }
          h3 .h3-in {padding-left:30px;}
        }
        &_info {
          min-height: auto;
        }
      }
    }
    .step-one {
      position: relative;
      width: calc(100% - 28px);
      margin-bottom: 40px;
      .text {
        font-size: 1.8rem;
        font-weight: bold;
      }
      figure {
        text-align: center;
      }
      &_info {
        padding: 20px;
        position: relative;
        min-height: 176px;
        figure {
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
      &_info-left {
        width: calc(100% - 190px);
        .subtext {
          margin-top: 20px;
          font-size: 1.6rem;
        }
        ul {
          margin-top: 20px;
          font-size: 1.6rem;
          border-top: dotted 1px $c-black;
          li {
            overflow: hidden;
            padding: 10px 0;
            border-bottom: dotted 1px $c-black;
            p {
              float: left;
              width: 220px;
              color: $c-main;
              font-weight: bold;
            }
            span {
              display: block;
              padding-left: 20px;
              overflow: hidden;
            }
          }
        }
      }
    }
    h3 {
      background: $c-main;
      color: $c-white;
      width: 100%;
      height: 56px;
      position: relative;
      @include en;
      line-height: 1;
      font-size: 2.5rem;
      font-weight: bold;
      span {
        display: inline-block;
        padding-left: 5px;
        font-size: 4rem;
      }
      &::before,
      &::after{
        position: absolute;
        margin: auto;
        content: "";
        vertical-align: middle;
        z-index: 0;
      }
      &::before{
        top: 0;
        right: -5px;
        width: 5px;
        height: 56px;
        background: #eee;
      }
      &::after{
        right: -45px;
        top: 0;
        box-sizing: border-box;
        width: 10px;
        height: 56px;
        border: 28px solid transparent;
        border-left: 12px solid #eee;
      }
      .h3-in {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;
        &::after{
          content:"";
          position: absolute;
          right: -40px;
          top: 0;
          box-sizing: border-box;
          width: 10px;
          height: 56px;
          border: 28px solid transparent;
          border-left: 12px solid $c-main;
          z-index: 1;
        }
      }
    }
  }
  .step-bottom {
    background: $c-white;
    width: 100%;
    max-width: 640px;
    margin: 20px auto;
    padding: 30px 15px;
    border: solid 2px $c-main;
    @include border-radius(10px);
    text-align: center;
    .title {
      color: $c-main;
      font-size: 2.6rem;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .text {
      font-size: 1.6rem;
    }
    .btn-area {
      margin-top: 20px;
      a {
        background: $c-main;
        color: $c-white;
        width: 280px;
        body:not(.smp) &:hover {
          background: $c-yellow;
          color: $c-main;
          svg {
            fill: $c-main;
          }
        }
        svg {
          fill: $c-white;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .step-list {
      .step-set {
        display: block;
        .step-one {
          width: calc(100% - 9px);
          &:nth-of-type(1) {z-index: 3;}
          &:nth-of-type(2) {
            z-index: 2;
            .text {
              padding-left: 0px;
            }
            h3 .h3-in {padding-left: 10px;}
          }
          &:nth-of-type(3) {
            z-index: 1;
            .text {
              padding-left: 0px;
            }
            h3 .h3-in {padding-left: 10px;}
          }
          &_info {
            min-height: auto;
          }
        }
      }
      .step-one {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        overflow: hidden;
        .text {
          font-size: 1.4rem;
          font-weight: bold;
        }
        figure {
          text-align: center;
          img {
            width: auto;
            max-width: 100%;
          }
        }
        &_info {
          padding: 15px 10px;
          position: relative;
          min-height: auto;
          figure {
            position: static;
          }
        }
        &_info-left {
          width: 100%;
          .subtext {
            margin-top: 20px;
            font-size: 1.6rem;
          }
          ul {
            margin-top: 20px;
            font-size: 1.4rem;
            border-top: dotted 1px $c-black;
            li {
              overflow: hidden;
              padding: 10px 0;
              border-bottom: dotted 1px $c-black;
              p {
                float: none;
                width: auto;
                color: $c-main;
                font-weight: bold;
              }
              span {
                display: block;
                padding-left: 0px;
                overflow: hidden;
              }
            }
          }
        }
      }
      h3 {
        background: $c-main;
        color: $c-white;
        width: calc(100% - 10px);
        height: 40px;
        position: relative;
        @include en;
        line-height: 1;
        font-size: 2rem;
        font-weight: bold;
        span {
          display: inline-block;
          padding-left: 5px;
          font-size: 3rem;
        }
        &::before,
        &::after{
          position: absolute;
          margin: auto;
          content: "";
          vertical-align: middle;
          z-index: 0;
        }
        &::before{
          top: 0;
          right: -5px;
          width: 5px;
          height: 40px;
          background: #eee;
        }
        &::after{
          right: -45px;
          top: 0;
          box-sizing: border-box;
          width: 10px;
          height: 40px;
          border: 28px solid transparent;
          border-left: 12px solid #eee;
        }
        .h3-in {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 10px;
          &::after{
            content:"";
            position: absolute;
            right: -28px;
            top: 0;
            box-sizing: border-box;
            width: 10px;
            height: 40px;
            border: 20px solid transparent;
            border-left: 8px solid $c-main;
            z-index: 1;
          }
        }
      }
    }
    .step-bottom {
      margin: 20px auto;
      padding: 20px 15px 25px;
      .title {
        color: $c-main;
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .text {
        font-size: 1.3rem;
      }
    }
  }
}
.lp-results {
  .content-width {
    max-width: 900px;
  }
  .results-lead {
    text-align: center;
  }
  .results-main {
    display: flex;
    justify-content: sb;
    margin-top: 50px;
    li {
      width: 50%;
      padding-left: calc((50/900)*100%);
      &:first-child {
        padding-left: 0;
        padding-right: calc((50/900)*100%);
        border-right: dotted 1px #ccc;
      }
      .title {
        font-size: 3rem;
        color: $c-main;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .text {
        font-size: 1.6rem;
      }
      figure {
        max-width: 180px;
        margin-top: 20px;
        text-align: center;
        img {
          width: 100%;
          border: #ccc solid 2px;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .results-main {
      display: block;
      li {
        width: 100%;
        padding-left: 0;
        &:first-child {
          padding-left: 0;
          padding-right: 0;
          border-right: none;
          border-bottom: dotted 1px #ccc;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 2rem;
        }
        .text {
          font-size: 1.3rem;
        }
        figure {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
.lp-price {
  .price-main {
    li {
      padding: 30px 40px 40px;
      border: solid 3px #ccc;
      &:first-child {
        margin-bottom: 40px;
      }
      .title {
        background: $c-main;
        padding: 16px;
        font-size: 2rem;
        color: $c-white;
        text-align: center;
        font-weight: bold;
        line-height: 1;
      }
      .text {
        p {
          margin: 30px 0;
          font-size: 2.8rem;
          text-align: center;
          font-weight: bold;
          text-align: center;
          span {
            display: inline-block;
            padding-bottom: 2px;
            background-image:
              linear-gradient(
                180deg,
                rgba(0,0,0,0) 0%,
                rgba(0,0,0,0) 95%,
                $c-yellow 95%,
                $c-yellow 100%
              );
            background-size:100% 2em;
            line-height:2;
          }
         }
      }
      .simple-price {
        overflow: hidden;
        margin: 30px 0;
        dt,dd {
          float: left;
          width: 50%;
          color: $c-main;
          font-size: 2rem;
        }
        dt {
          text-align: right;
        }
        dd {
          font-weight: bold;
        }
      }
      .price-table {
        margin-top: 20px;
        border-collapse: collapse;
        font-size: 1.6rem;
        tr {
          .t1 {width: calc((300/880)*100%);}
          .t4 {width: calc((220/880)*100%);}
          th,td {
            padding: 5px;
            border: solid 1px $c-main;
          }
          th {
            background: $c-blue;
            color: $c-white;
            text-align: left;
            &:nth-of-type(1) {
              padding-left: 10px;
            }
          }
          td {
            text-align: center;
            font-weight: bold;
            &:nth-of-type(1) {
              font-weight: normal;
              text-align: left;
              padding-left: 10px;
              &.tac {text-align: center;}
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .price-main {
      li {
        padding: 10px 10px 20px;
        border: solid 2px #ccc;
        &:first-child {
          margin-bottom: 30px;
        }
        .title {
          background: $c-main;
          padding: 12px;
          font-size: 1.6rem;
        }
        .text {
          padding: 0 10px;
          p {
            margin: 15px 0;
            font-size: 2rem;
          }
        }
        .simple-price {
          overflow: hidden;
          margin: 20px 0;
          padding: 0 10px;
          dt,dd {
            float: none;
            width: 100%;
            color: $c-main;
            font-size: 1.3rem;
          }
          dt {
            text-align: left;
          }
          dd {
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
        .price-table {
          margin-top: 20px;
          border-collapse: collapse;
          font-size: 1.2rem;
          tr {
            .t1 {width: calc((300/880)*100%);}
            .t4 {width: calc((220/880)*100%);}
            th,td {
              padding: 5px 3px;
              border: solid 1px $c-main;
            }
            th {
              &:nth-of-type(1) {
                padding-left: 5px;
              }
            }
            td {
              &:nth-of-type(1) {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.lp-faq {
  .content-width {
    max-width: 900px;
  }
  .faq-list {
    li {
      margin-top: 40px;
      line-height: 1.5;
      dl {
        overflow: hidden;
        &.q {
          color: $c-main;
          border-bottom: 1px dashed #070707;
          padding-bottom: 5px;
          dd {
            font-size: 2.6rem;
            font-weight: bold;
          }
        }
        &.a {
          dt {
            color: $c-blue;
          }
          dd {
            padding-top: 0.5em;
          }
        }
        dt {
          float: left;
          margin-right: 10px;
          font-size: 3rem;
          font-weight: bold;
          @include en;
        }
        dd {
          font-size: 1.8rem;
          overflow: hidden;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .faq-list {
      li {
        margin-top: 30px;
        dl {
          &.q {
            dd {
              font-size: 1.8rem;
            }
          }
          &.a {
            dd {
              padding-top: 0.5em;
            }
          }
          dt {
            font-size: 2rem;
          }
          dd {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}
.lp-voice {
  .content-width {
    max-width: 900px;
  }
  .voice-list {
    li {
      border-top: 1px dashed #070707;
      padding: 20px 0 10px;
      &:first-child {
        border-top: none;
      }
      .title {
        color: $c-main;
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        margin: 20px 0;
        font-size: 1.6rem;
      }
      figure {
        img {
          width: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .voice-list {
      li {
        padding: 20px 0 10px;
        .title {
          font-size: 2.4rem;
          margin-bottom: 10px;
        }
        p {
          margin: 10px 0;
          font-size: 1.4rem;
        }
        figure {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
.lp-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/puzzle/message_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;
  &::after {
    content:"";
    background: rgba(0,0,0,0.64);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .message-main {
    background: rgba(#000,0.75);
    color: $c-white;
    padding: 50px;
    text-align: center;
    z-index: 1;
    .title {
      font-size: 4.8rem;
      line-height: 1;
      padding-bottom: 40px;
      @include mincho;
      position: relative;
      &:after {
        content:"";
        background-color: $c-yellow;
        width: 120px;
        height: 1px;
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -60px;
      }
    }
    p {
      font-size: 1.8rem;
      margin-top: 30px;
    }
    .btn-area {
      margin-top: 50px;
      a {
        &.is-big {
          &::after {
            display: none;
          }
        }
        .btn-in {
          padding-left: 30px;
        }
        .icon_mail_dims {
          width: 24px;
          height: 16.82px;
          margin-top: -8.4px;
        }
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    height: 600px;
    padding: 0 20px;
    position: relative;
    .message-main {
      background: rgba(#000,0.75);
      color: $c-white;
      padding: 30px;
      text-align: center;
      z-index: 1;
      .title {
        font-size: 3rem;
        line-height: 1;
        padding-bottom: 20px;
        margin-bottom: 20px;
        @include mincho;
        position: relative;
        &:after {
          content:"";
          background-color: $c-yellow;
          width: 64px;
          height: 1px;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -32px;
        }
      }
      p {
        font-size: 1.4rem;
        margin-top: 3px;
      }
      .btn-area {
        margin-top: 30px;
        a {
          &.is-big {
            &::after {
              display: none;
            }
          }
          .btn-in {
            padding-left: 30px;
          }
          .icon_mail_dims {
            width: 24px;
            height: 16.82px;
            margin-top: -8.4px;
          }
        }
      }
    }
  }
}
/* ========================================
 TOP Setting
======================================== */
.achievement-content figure {
  line-height: 0;
  .slick-dots {
    bottom: 30px;
    li {
      &:after {
        height: 6px;
      }
      button {
        width: 40px;
        height: 6px;
      }
    }
  }
}
.slick-dots {
  width: 100%;
  height: 4px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 50px;
  li {
    display: inline-block;
    height: 4px;
    margin: 0 10px;
    position: relative;
    &.slick-active {
      &:after {
        left: 0;
        width: 100%;
      }
    }
    &:after {
      content:"";
      background-color: $c-yellow;
      width: 0;
      height: 4px;
      position: absolute;
      right: 0;
      top: 0;
      transition: width 0.4s;
    }
    button {
      display: block;
      background-color: #fff;
      width: 100px;
      height: 4px;
      text-indent: -9999px;
      @include border-radius(0);
    }
  }
}
.main-visual {
  background-color: $c-black;
  // display: flex;
  // align-items: center;
  width: 100%;
  height: 800px;
  position: relative;
  .main-title {
    width: 100%;
    position: absolute;
    top: 100px;
    left: 0;
    z-index: 1;
    .inner {
      width: 100%;
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 50px;
    }
    .text-wrap {
      display: inline-block;
      background: rgba(0,0,0,0.64);
      color: #fff;
      padding: 50px;
      .text1 {
        margin-bottom: 80px;
        font-size: 5.8rem;
        line-height: 1.38;
        position: relative;
        @include mincho;
        &:after {
          content:"";
          width: 120px;
          height: 1px;
          background-color: $c-yellow;
          position: absolute;
          left: 0;
          bottom: -40px;
        }
      }
      .text2 {
        font-size: 1.8rem;
        line-height: 2;
      }
    }
  }
  .slider-wrap {
    width: 100%;
    height: 800px;
    overflow: hidden;
    .slide {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 800px;
      position: relative;
      &::after {
        content:"";
        background: rgba(0,0,0,0.4);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    height: 100%;
    min-height: 600px;
    html.is-block & {
      @include animation(FadeInLeft .6s cubic-bezier(.445,.05,.55,.95) reverse);
    }
    .main-title {
      .inner {
        padding: 0 20px;
        text-align: center;
      }
      .text-wrap {
        display: inline-block;
        background: rgba(0,0,0,0.64);
        color: #fff;
        padding: 25px;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
        .text1 {
          margin-bottom: 40px;
          font-size: 2.8rem;
          line-height: 1.25;
          &:after {
            content:"";
            width: 64px;
            bottom: -20px;
          }
        }
        .text2 {
          font-size: 1.2rem;
          line-height: 1.66;
        }
      }
    }
    .slider-wrap {
      height: 100%;
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide {
            height: 100%;
            > div {
              height: 100%;
              .slide {
                height: 100%;
              }
            }
          }
        }
      }
      .slick-dots {
        width: 100%;
        height: 4px;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 20px;
        li {
          display: inline-block;
          height: 4px;
          margin: 0 6px;
          position: relative;
          &.slick-active {
            &:after {
              left: 0;
              width: 100%;
            }
          }
          &:after {
            content:"";
            background-color: $c-yellow;
            width: 0;
            height: 4px;
            position: absolute;
            right: 0;
            top: 0;
            transition: width 0.4s;
          }
          button {
            display: block;
            background-color: #fff;
            width: 30px;
            height: 4px;
            text-indent: -9999px;
          }
        }
      }
    }
  }
}
.service-block {
  .service-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      display: flex;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      &.is-puzzle {
        width: 100%;
        margin-bottom: 60px;
        justify-content: center;
        .lp-block,
        .info-block {
          width: 50%;
          min-height: 480px;
        }
        .lp-block {
          display: flex;
          justify-content: center;
          align-items: center;
          background: $c-main;
          color: #fff;
          font-weight: bold;
          text-align: center;
          letter-spacing: 0.05em;
          position: relative;
          &::after {
            content:"";
            width: calc((100% - 20px));
            height: calc((100% - 20px));
            position: absolute;
            left: 10px;
            top: 10px;
            z-index: 0;
            border: solid 1px $c-blue;
          }
          .flex-in {
            width: 100%;
            position: relative;
            z-index: 1;
          }
          .lead {
            font-style: 1.5rem;
          }
          .icons {
            margin-top: 30px;
            display: flex;
            justify-content: center;
            li {
              display: inline-block;
              width: calc((80/480)*100%);
              height: auto;
              margin-top: 0;
              margin-bottom: 0;
              margin-right: calc((10/480)*100%);
              margin-left: calc((10/480)*100%);
              svg {
                width: 100%;
              }
            }
          }
          .title {
            margin-top: 40px;
            padding-top: 40px;
            font-size: 2.4rem;
            position: relative;
            &::before {
              content:"";
              background: #fff;
              width: 64px;
              height: 1px;
              position: absolute;
              left: 50%;
              top: 0;
              margin-left: -32px;
            }
            span {
              font-size: 3.4rem;
              color: $c-yellow;
            }
          }
        }
        .info-block .photo {
          height: 240px;
          .category {
            font-size: 3rem;
            i svg {
              width: 60px;
              height: 60px;
            }
          }
        }
      }
      &.is-2nd {
        width: calc((450/960)*100%);
        margin-bottom: 60px;
        .info-block .photo {
          height: 225px;
          .category {
            font-size: 3rem;
            i svg {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
      &.is-3rd {
        width: calc((300/960)*100%);
        .info-block {
          min-height: auto;
          .photo {
            height: 150px;
            .category {
              font-size: 2.4rem;
              i {
                margin-bottom: 12px;
                svg {
                  width: 44px;
                  height: 44px;
                }
              }
            }
          }
          .information {
            height: auto;
          }
        }
      }
      .info-block {
        background: #fff;
        width: 100%;
        min-height: 450px;
        .photo {
          width: 100%;
          position: relative;
          &::before {
            content:"";
            background: rgba(0,0,0,0.64);
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
          }
          figure {
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          .category {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            font-weight: bold;
            color: #fff;
            i {
              display: block;
              text-align: center;
              margin-bottom: 20px;
              svg {
                fill: $c-yellow;
              }
            }
            .en {
              @include en;
              line-height: 1;
              letter-spacing: 0.1em;
            }
          }
        }
        .information {
          position: relative;
          height: 50%;
          padding: 20px 28px 30px;
          h3{
            color: $c-main;
            font-size: 2.6rem;
            font-weight: bold;
            @include mincho;
            text-align: center;
          }
          .lead {
            margin-top: 10px;
            font-size: 1.4rem;
            letter-spacing: 0;
          }
          .btn-area {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 24px;
          }
        }
      }
    }
    @media only screen and (max-width: $pc-width) {
      padding: 0 20px;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .service-list {
      padding: 0;
      li {
        display: block;
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        &.is-puzzle {
          width: 100%;
          margin-bottom: 0;
          .lp-block,
          .info-block {
            width: 100%;
            min-height: auto;
          }
          .lp-block {
            background: $c-main;
            color: #fff;
            padding: 50px 0;
            font-weight: bold;
            text-align: center;
            letter-spacing: 0.05em;
            position: relative;
            .flex-in {
              width: 100%;
              position: relative;
              z-index: 1;
            }
            .lead {
              font-style: 1.5rem;
            }
            .icons {
              margin-top: 10px;
              li {
                display: inline-block;
                width: calc((80/480)*100%);
                height: auto;
                margin: 0 calc(((10/480)*100%));
                svg {
                  width: 100%;
                }
              }
            }
            .title {
              margin-top: 20px;
              padding-top: 20px;
              font-size: 1.5rem;
              position: relative;
              &::before {
                content:"";
                background: #fff;
                width: 50px;
                height: 1px;
                position: absolute;
                left: 50%;
                top: 0;
                margin-left: -25px;
              }
              span {
                font-size: 2.2rem;
                color: $c-yellow;
              }
            }
          }
          .info-block {
            .photo {
              height: 150px;
              .category {
                font-size: 2rem;
                i svg {
                  width: 40px;
                  height: 40px;
                }
              }
            }
            .information {
              .btn-area {
                display: block;
                overflow: hidden;
                a {
                  &:nth-of-type(1) {
                    float: left;
                    margin: 0;
                    width: 48%;
                  }
                  &:nth-of-type(2) {
                    float: right;
                    margin: 0;
                    width: 48%;
                  }
                }
              }
            }
          }
        }
        &.is-2nd {
          width: 100%;
          margin-bottom: 0px;
          .info-block .photo {
            height: 150px;
            .category {
              font-size: 2rem;
              i svg {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
        &.is-3rd {
          width: 100%;
          .info-block {
            min-height: auto;
            .photo {
              height: 150px;
              .category {
                font-size: 2rem;
                i {
                  margin-bottom: 12px;
                  svg {
                    width: 40px;
                    height: 40px;
                  }
                }
              }
            }
          }
        }
        .info-block {
          background: #fff;
          width: 100%;
          min-height: auto;
          .photo {
            width: 100%;
            position: relative;
            .category {
              i {
                margin-bottom: 15px;
                svg {
                  fill: $c-yellow;
                }
              }
              .en {
                @include en;
                line-height: 1;
                letter-spacing: 0.1em;
              }
            }
          }
          .information {
            position: relative;
            height: auto;
            padding: 20px 20px 30px;
            h3{
              font-size: 2rem;
            }
            .lead {
              margin-top: 10px;
              font-size: 1.3rem;
            }
            .btn-area {
              display: flex;
              justify-content: center;
              width: 100%;
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}
.staff-block {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../img/top/staff_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 480px;
  margin-bottom: 100px;
  position: relative;
  &::before {
    content:"";
    background: rgba(0,0,0,0.64);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .inner {
    position: relative;
    z-index: 1;
  }
  h2 {
    &:after {
      background-color: #fff;
    }
    .en, .jp {
      color: #fff;
    }
  }
  .lead {
    color: #fff;
    font-size: 1.8rem;
    text-align: center;
    line-height: 2;
  }
  .btn-area {
    margin-top: 50px;
    a {
      width: 200px;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    background-image: url(../img/top/staff_bg_sp.png);
    height: auto;
    margin-bottom: 50px;
    padding: 50px 0;
    .lead {
      font-size: 1.3rem;
    }
    .btn-area {
      margin-top: 30px;
      a {
        width: 160px;
      }
    }
  }
}
.top-btns {
  margin-top: 40px;
  text-shadow: none;
  text-align: center;
  font-size: 0;
  position: absolute;
  right: 40px;
  bottom: 80px;
  z-index: 1;
  @media only screen and (max-width: $breakpoint) {
    width: 100%;
    right: 0;
    bottom: 50px;
    padding: 0 20px;
  }
  a {
    display: inline-block;
    background: #fff;
    color: $c-main;
    margin: 10px 20px 15px;
    font-size: 14px;
    line-height: 1;
    border-radius: 6px;
    transition: 300ms all ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    @media only screen and (max-width: $breakpoint) {
      line-height: 1.5;
      max-width: 420px;
      margin: 0 auto;
    }
    .btn-wrap {
      display: table;
      width: 100%;
      margin: 0;
      &::after {
        display: none;
      }
    }
    .icon {
      background: $c-main;
      display: table-cell;
      width: 60px;
      position: relative;
      transition: 300ms all ease-in-out;
    }
    img {
      width: 64%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .btn-right {
      display: table-cell;
      background: #fff;
      font-weight: bold;
      text-align: left;
      padding: 15px;
      @media only screen and (max-width: $breakpoint) {
        padding: 10px 15px;
      }
    }
    .text1 {
      display: inline-block;
      color: #333;
      margin-bottom: 10px;
      font-size: 11px;
      transition: 300ms all ease-in-out;
      font-weight: normal;
      @media only screen and (max-width: $breakpoint) {
        margin-bottom: 5px;
      }
    }
    .text2 {
      font-size: 11px;
      display: block;
      margin-top: 5px;
    }
    .text2 br {
      display: none;
    }
  }
}
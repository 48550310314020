/* ========================================
 Common Setting
======================================== */
html {
  &.is-block {
    overflow: hidden;
  }
}
body {
  padding-top: 100px;
  @media only screen and (max-width: $breakpoint) {
    padding-top: 0;
  }
}
#content-wrapper {
  @media only screen and (max-width: $breakpoint) {
    html.is-block & {
      @include animation(FadeInLeft .6s cubic-bezier(.445,.05,.55,.95) reverse);
    }
  }
  .bg-gray {
    background: #eee;
  }
}
.content-width {
  width: 100%;
  max-width: $pc-width;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  .content-width-in {
    width: 100%;
    max-width: $pc-width-in;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: $pc-width) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.content-inner {
  padding-top: 100px;
  padding-bottom: 150px;
  @media only screen and (max-width: $breakpoint) {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

#pagetop {
  position: relative;
  &.is-fixed a {
    position: fixed;
    right: 20px;
    bottom: 0;
  }
  a {
    display: flex;
    justify-content:center;
    align-items: center;
    background: $c-main;
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 8888;
    right: 20px;
    bottom: 0;
    margin-bottom: 20px;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    @include border-radius(4px);
    transition: all 0.2s;
    &::before,
    &::after {
      content:"";
      transition: all 0.2s;
    }
    &::before {
      width: 2px;
      height: 14px;
      background: #fff;
    }
    &::after {
      width: 12px;
      height: 12px;
      border-top: solid #fff 2px;
      border-right: solid #fff 2px;
      transform: rotate(-45deg);
      position: absolute;
      margin-top: -2px;
    }
    &:hover {
      background: $c-blue;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    display: none;
    height: 60px;
    &.is-fixed a {
      right: 10px;
    }
    a {
      width: 40px;
      height: 40px;
      right: 10px;
      margin-bottom: 10px;
    }
  }
}

.flex-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  &.abs {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

h2 {
  text-align: center;
  margin-bottom: 50px;
  padding-bottom: 50px;
  position: relative;
  &:after {
    content:"";
    background-color: $c-black;
    width: 64px;
    height: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -32px;
  }
  .en {
    @include en;
    font-size: 2.2rem;
  }
  .jp {
    color: $c-main;
    @include mincho;
    font-size: 3.6rem;
    font-weight: bold;
    letter-spacing: 0.1em;
  }
  @media only screen and (max-width: $breakpoint) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    &:after {
      width: 50px;
    }
    .en {
      font-size: 1.4rem;
    }
    .jp {
      font-size: 2.4rem;
    }
  }
}
/* ----------------------------------------
 Button
---------------------------------------- */
.btn-wrap {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  &::after {
    content: "";
    width: 12px;
    height: 12px;
    border-top: solid 3px $c-white;
    border-right: solid 3px $c-white;
    position: absolute;
    right: 30px;
    top: 50%;
    z-index: 1;
    margin-top: -6px;
    transform: rotate(45deg);
    transition: all 0.2s;
  }
  &.is-left {
    &::after {
      right: auto;
      left: 30px;
      transform: rotate(-135deg);
    }
  }
  input[type="submit"] {
    margin: 0;
  }
}
.btn-area {
  display: flex;
  justify-content:center;
}
// MW WP FORM
input[type="submit"],
.btn {
  display: flex;
  justify-content:center;
  align-items: center;
  background: #fff;
  color: $c-black;
  width: 160px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 40px;
  transition: all 0.2s;
  body:not(.smp) &:hover {
    background: $c-blue;
    color: $c-white;
    &.btn-contact {
      background: $c-blue;
      color: $c-white;
      svg {
        fill: $c-white;
      }
    }
    &.is-blue {
      background: $c-blue;
      color: $c-white;
    }
    &.is-yellow {
      background: $c-blue;
      color: $c-white;
    }
    // &.is-big::after {
    //   border-top: solid 3px $c-main;
    //   border-right: solid 3px $c-main;
    // }
  }
  span {
    display: inline-block;
    padding-bottom: 3px;
  }
  &.btn-contact {
    background: #FFF;
    color: $c-main;
    svg {
      transition: all 0.2s;
    }
  }
  &.is-blue {
    background: $c-main;
    color: $c-white;
  }
  &.is-yellow {
    background: $c-yellow;
    color: $c-main;
    letter-spacing: 0;
  }
  &.is-big {
    width: 420px;
    height: 80px;
    font-size: 2rem;
    position: relative;
    &::after {
      content: "";
      width: 12px;
      height: 12px;
      border-top: solid 3px $c-white;
      border-right: solid 3px $c-white;
      position: absolute;
      right: 30px;
      top: 50%;
      margin-top: -6px;
      transform: rotate(45deg);
      transition: all 0.2s;
    }
    &.is-left {
      &::after {
        right: auto;
        left: 30px;
        transform: rotate(-135deg);
      }
    }
  }
  // ICON
  .btn-in {
    position: relative;
    padding-left: 20px;
    svg {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -5.6px;
      fill: $c-main;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    font-size: 1.3rem;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: 0.02em;
    &.is-big {
      width: 100%;
      max-width: 280px;
      height: 60px;
      font-size: 1.6rem;
      &::after {
        right: 20px;
      }
    }
  }
}

/* ----------------------------------------
 Header
---------------------------------------- */
header {
  background: rgba(255,255,255,.96);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100px;
  box-shadow:0 0 10px rgba(0,0,0,0.2);
  .header-in {
    display: flex;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-left: calc((50/1280)*100%);
    transition: all 0.2s;
    svg {
      fill: $c-main;
      transition: all 0.2s;
    }
  }
  .nav-wrap {
    overflow: hidden;
  }
  nav {
    display: flex;
    align-items: center;
    height: 100px;
    margin-right: 35px;
    float: left;
    font-size: 1.5rem;
    line-height: 1.2;
    .inner {
      display: flex;
      justify-content:center;
      align-items: center;
    }
    a {
      color: $c-main;
      &.active {
        span:after {
          width: 100%;
          left: 0;
        }
      }
      body:not(.smp) &:hover {
        span:after {
          width: 100%;
          left: 0;
        }
      }
      span {
        display: inline-block;
        margin: 0 15px;
        padding: 6px 0;
        position: relative;
        &:after {
          content:"";
          background: $c-yellow;
          width: 0;
          height: 2px;
          transition: all 0.2s;
          position: absolute;
          right: 0;
          bottom: 0;
        }
        br {
          display: none;
        }
      }
    }
  }
  .contact-area {
    @include flex-center;
    background: $c-main;
    color: #FFF;
    width: 200px;
    height: 100px;
    text-align: center;
    float: right;
    .btn-contact {
      @include flex-center;
      width: 150px;
      height: 40px;
      @include border-radius(40px);
      font-size: 1.4rem;
      line-height: 1.2;
      font-weight: bold;
    }
    .tel {
      margin-top: 8px;
      @include en;
      font-weight: bold;
      line-height: 1;
      span {
        font-size: 1.3rem;
      }
      .num {
        display: inline-block;
        margin-left: 0.3em;
        font-size: 1.7rem;
      }
    }
  }
  @media only screen and (max-width: $breakpoint-lg) {
    .logo {
      width: 110px;
      margin-left: calc((30/1280)*100%);
      svg {
        width: 100%;
      }
    }
    nav {
      margin-right: 15px;
      a {
        span {
          margin: 0 10px;
        }
      }
    }
    .contact-area {
      width: 180px;
    }
  }
  @media only screen and (max-width: 1080px) {
    nav {
      font-size: 1.3rem;
      a {
        span {
          margin: 0 6px;
          br {
            display: inline;
          }
        }
      }
    }
  }
  @media only screen and (max-width: $pc-width) {
    .logo {
      width: 90px;
    }
    .contact-area {
      .btn-contact {
        width: 140px;
      }
    }
    .contact-area {
      width: 160px;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    background: none;
    height: auto;
    position: absolute;
    .header-in {
      display: block;
      position: relative;
      &::after {
        content:"";
        width: 100%;
        height: 80px;
        background: rgba(0,0,0,0.75);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 8888;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s;
      }
      body.is-scroll & {
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .logo {
      margin-left: 0;
      position: fixed;
      left: 20px;
      top: 20px;
      z-index: 9999;
      svg {
        width: 90px;
        height: 54.65px;
        fill: $c-white;
        transition: all 0.2s;
      }
      body.is-scroll & svg{
        width: 70px;
        height: 42.5px;
      }
    }
    .sp-navbtn {
      padding-top: 4px;
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 9999;
      html.is-block & {
        .bar {
          &::before {
            transform: translate3d(0,8px,0) rotate(45deg);
          }
          &::after {
            transform: translate3d(0,-8px,0) rotate(-45deg);
          }
          .bar-c {
            opacity: 0;
          }
        }
      }
      .bar {
        position: relative;
        &::before,
        &::after {
          content: "";
          width: 30px;
          height: 1px;
          background: $c-white;
          position: absolute;
          left: 0;
          transition: all 0.2s;
        }
        &::before {
          top: -8px;
        }
        &::after {
          bottom: -8px;
        }
        .bar-c {
          background: $c-white;
          width: 30px;
          height: 1px;
          margin: 8px 0;
          transition: all 0.2s;
        }
      }
      .text {
        margin-top: 16px;
        color: $c-white;
        font-size: 1rem;
        line-height: 1;
      }
    }
    .nav-wrap {
      background: $c-black;
      color: $c-white;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 9990;
      width: 100%;
      height: 100%;
      overflow: auto;
      transform: translate3d(100%,0,0);
      transition: all 0.4s;
      html.is-block & {
        transform: translate3d(0,0,0);
      }
      .nav-wrap-in {
        @include pos-center;
      }
    }
    nav {
      display: block;
      width: 100%;
      height: auto;
      margin-right: 0;
      float: none;
      font-size: 1.5rem;
      .inner {
        display: block;
      }
      a {
        display: block;
        color: $c-white;
        position: relative;
        margin-bottom: 6px;
        padding-left: 20px;
        &::after {
          content:"";
          width: 12px;
          height: 1px;
          background: $c-yellow;
          position: absolute;
          left: 0;
          top: 50%;
        }
        span {
          display: inline-block;
          margin: 0;
          padding: 6px 0;
          position: relative;
          &:after {
            content:"";
            background: $c-yellow;
            width: 0;
            height: 2px;
            transition: all 0.2s;
            position: absolute;
            right: 0;
            bottom: 0;
          }
          br {
            display: none;
          }
        }
      }
    }
    .contact-area {
      background: none;
      width: 100%;
      height: auto;
      float: none;
      margin-top: 20px;
    }
  }
}
/* ----------------------------------------
 Footer
---------------------------------------- */
footer {
  .info {
    background: $c-black;
    padding: 50px 30px;
    .logo-set {
      display: flex;
      justify-content:center;
      align-items: center;
      figure {
        svg {
          fill: #fff;
          width: 130px;
          height: 78.94px;
        }
      }
      .text {
        color: #fff;
        margin-left: 30px;
        font-size: 1.5rem;
        line-height: 2;
      }
    }
    .btn-area {
      margin-top: 30px;
      a {
        width: 280px;
        .btn-in {
          padding-left: 24px;
        }
      }
      .text-link {
        display: block;
        width: auto;
        margin-top: 40px;
        color: $c-white;
        text-align: center;
        text-decoration: underline;
      }
    }
  }
  .copy {
    color: #666;
    padding: 30px;
    font-size: 1.3rem;
    text-align: center;
  }
  @media only screen and (max-width: $breakpoint) {
    html.is-block & {
      @include animation(FadeInLeft .6s cubic-bezier(.445,.05,.55,.95) reverse);
    }
    .info {
      background: $c-black;
      padding: 50px;
      .logo-set {
        display: block;
        text-align: center;
        figure {
          svg {
            fill: #fff;
            width: 130px;
            height: 78.94px;
          }
        }
        .text {
          color: #fff;
          margin-top: 30px;
          margin-left: 0px;
          font-size: 1.3rem;
          line-height: 2;
          text-align: center;
        }
      }
      .btn-area {
        a {
          width: 240px;
        }
      }
    }
    .copy {
      padding: 20px 5px;
      font-size: 1.2rem;
      letter-spacing: 0;
    }
  }
}

/* ----------------------------------------
 Animation
---------------------------------------- */
@include keyframes(FadeInRight) {
  0% {
    opacity: 0;
    transform: translate3d(15px,0,0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
    visibility: visible;
  }
}
@include keyframes(FadeInLeft) {
  0% {
    opacity: 0;
    transform: translate3d(-15px,0,0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translate3d(0,0,0);
    visibility: visible;
  }
}

/* ----------------------------------------
 Loading
---------------------------------------- */
.loading {
  background: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content:center;
  .sk-circle {
  width: 40px;
  height: 40px;
  position: absolute; }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $c-blue;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }
  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg); }
  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg); }
  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg); }
  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg); }
  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg); }
  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg); }
  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg); }
  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); }
  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); }
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; }
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; }
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; }
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; }
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; }
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; }
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; }
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s; }
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; }
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s; }
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s; }
@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
}
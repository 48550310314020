/* ========================================
 Under Setting
======================================== */

.under-visual {
  display: flex;
  justify-content:center;
  align-items:center;
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  width: 100%;
  height: 300px;
  position: relative;
  &::after {
    content:"";
    background: rgba(0,0,0,0.64);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  &.is-achievement {background-image: url(../img/visual_achievement.png);}
  &.is-about {background-image: url(../img/visual_about.png);}
  &.is-staff {background-image: url(../img/visual_staff.png);}
  &.is-contact {background-image: url(../img/visual_contact.png);}
  &.is-page {background-image: url(../img/visual_page.png);}
  h2 {
    position: relative;
    margin-bottom: 0;
    z-index: 1;
    &:after {
      content:"";
      background-color: $c-yellow;
    }
    .en {
      color: $c-white;
    }
    .jp {
      color: $c-white;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    height: 240px;
    h2 {
      position: absolute;
      bottom: 50px;
    }
  }
}
@media only screen and (max-width: $breakpoint) {
  
}
.breadcrumb {
  position: absolute;
  left: 0;
  top: 20px;
  @media only screen and (max-width: $pc-width) {
    left: 20px;
  }
  @media only screen and (max-width: $breakpoint) {
    display: none;
  }
}

.table-layout {
  width: 100%;
  margin-top: 50px;
  border-top: 1px solid $c-main;
  border-bottom: 1px solid $c-main;
  font-size: 1.5rem;
  tr {
    border-top: 1px solid #ddd;
    &:nth-of-type(1) {
      border-top: none;
    }
  }
  th,td {
    vertical-align: top;
    padding: 12px 0 15px;
  }
  th {
    width: calc((200/800)*100%);
    font-weight: bold;
  }
  @media only screen and (max-width: $breakpoint) {
    margin-top: 40px;
    font-size: 1.3rem;
    th {
      width: 100px;
    }
  }
}

/* ----------------------------------------
 About
---------------------------------------- */
.content-about{
  .logo {
    text-align: center;
    svg {
      fill: $c-main;
      width: 200px;
      height: 121.44px;
    }
  }
  .table-layout {
    tr {
      &.map {
        border-top: none;
        td {
          padding-left: calc((200/800)*100%);
          iframe {
            width: 100%;
          }
        }
      }
    }
    td {
      .btn-area {
        margin: 15px 0;
        .btn {
          width: 280px;
          .btn-in {
            svg {
              fill: $c-white;
              transition: all 0.2s;
            }
          }
        }
      }
    }
  }
  .btn-area {
    margin-top: 60px;
    display: block;
    a {
      width: 280px;
      margin: 30px auto 0;
      &:nth-of-type(1){
        margin-top: 0;
      }
      &.btn-puzzle {
        background: $c-blue;
        color: $c-white;
        body:not(.smp) &:hover {
          background: $c-yellow;
          color: $c-main;
        }
      }
      &.btn-contact {
        background: $c-main;
        color: $c-white;
        svg {
          fill: $c-white;
        }
      }
    }
  }
  .inc-block {
    display: flex;
    justify-content:center;
    align-items:center;
    width: 100%;
    max-width: 540px;
    height: 150px;
    margin: 60px auto 0;
    padding-right: 40px;
    border: solid 2px $c-main;
    position: relative;
    color: $c-black;
    &::before,
    &::after {
      content: "";
      position: absolute;
    }
    &::before {
      background: $c-main;
      width: 20px;
      height: 80px;
      right: 10px;
      top: 50%;
      margin-top: -40px;
      z-index: 1;
    }
    &::after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $c-white;
      right: 17px;
      top: 50%;
      margin-top: -6px;
      z-index: 2;
    }
    .text {
      margin-left: 40px;
      font-size: 1.6rem;
      line-height: 2;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .logo {
      svg {
        width: 120px;
        height: 72.86px;
      }
    }
    .table-layout {
      tr {
        &.map {
          td {
            padding-top: 0;
            padding-left: 0;
            .btn-area {
              .btn {
                width: 240px;
              }
            }
            iframe {
              height: 300px;
            }
          }
        }
      }
    }
    .btn-area {
      a {
        width: 240px;
      }
    }
    .inc-block {
      display: block;
      max-width: 280px;
      height: auto;
      margin: 40px auto 0;
      padding: 20px 30px 15px;
      &::before {
        width: 20px;
        height: 160px;
        margin-top: -80px;
      }
      .text {
        margin-top: 20px;
        margin-left: 0px;
        font-size: 1.4rem;
      }
    }
  }
}

/* ----------------------------------------
 Staff
---------------------------------------- */
.content-staff {
  text-align: center;
  h3 {
    color: $c-main;
    font-size: 3rem;
    @include mincho;
    font-weight: bold;
  }
  .staff-main {
    margin-top: 50px;
    padding: 50px 20px;
    font-size: 1.6rem;
    border-top: 1px solid $c-main;
    border-bottom: 1px solid $c-main;
    line-height: 2;
    .marker {
      display: inline-block;
      color: $c-main;
      padding: 0 0.2em;
      font-weight: bold;
      background: linear-gradient(transparent 50%, $c-yellow 50%, $c-yellow 85%,transparent 85%);
    }
  }
  .btn-area {
    margin-top: 50px;
  }
  @media only screen and (max-width: $breakpoint) {
    h3 {
      font-size: 2.2rem;
    }
    .staff-main {
      margin-top: 30px;
      text-align: left;
      font-size: 1.4rem;
      padding: 30px 0px;
      line-height: 1.66;
      .marker {
        background: linear-gradient(transparent 50%, $c-yellow 50%, $c-yellow 90%,transparent 90%);
      }
    }
  }
}

/* ----------------------------------------
 Contact
---------------------------------------- */
.content-contact {
  .lead {
    margin-bottom: 50px;
    text-align: center;
    font-size: 1.6rem;
  }
  ul.btn-area {
    border-top: 1px solid $c-main;
    padding-top: 50px;
    justify-content: space-between;
  }
  .btn-area {
    margin-top: 50px;
    text-align: center;
    li {
      width: calc((380/800)*100%);
      p {
        margin-bottom: 20px;
      }
      a {
        width: 100%;
        margin: 0;
      }
    }
  }
  .table-layout {
    th,td {
      vertical-align: middle;
      padding: 20px 0;
      &.vt {
        vertical-align: top;
      }
    }
    th {
      width: 30%;
      .required {
        display: inline-block;
        background: $c-red;
        color: $c-white;
        margin-left: 5px;
        padding: 2px 4px;
        font-size: 1.2rem;
        line-height: 1.2;
        @include border-radius(4px);
      }
      .note {
        display: inline-block;
        margin-left: 5px;
        font-size: 1.2rem;
        font-weight: normal;
      }
    }
  }
  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    padding: 15px;
    border: solid 1px $c-form-gray;
    font-size: 1.6rem;
    vertical-align: middle;
    @include border-radius(4px);
    &.is-half {
      width: 50%;
    }
  }
  input[type='text'],
  input[type='email'] {
    height: 50px;
  }
  textarea {
    height: 120px;
    &#mail-content {
      height: 150px;
    }
  }
  // MW WP FORM
  .mw_wp_form {
    &.mw_wp_form_confirm {
      .confirm-hide {
        display: none;
      }
      .form-btns {
        margin-top: 50px;
        text-align: center;
        input,
        button {
          max-width: 320px;
          margin: 0　15px;
          display: inline-block;
        }
      }
    }
    .horizontal-item {
      display: block;
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
      & + .horizontal-item {
        margin-left: 0;
      }
    }
    @media only screen and (max-width: $breakpoint) {
      &.mw_wp_form_confirm {
        .form-btns {
          input,
          button {
            margin-top: 20px;
            &:nth-of-type(1) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  
  .radio-list {
    li {
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + span {
    display: block;
    padding-left: 32px;
    position: relative;
    line-height: 22px;
    cursor: pointer;
    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      @include border-radius(24px);
      border: 1px solid #808080;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      @include border-radius(10px);
      position: absolute;
      left: 7px;
      top: 7px;
      transition: all 0.3s;
    }
  }
  input[type='radio']:checked + span {
    &:after {
      background: #4D4D4D;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .lead {
      margin-bottom: 30px;
      font-size: 1.4rem;
    }
    ul.btn-area {
      display: block;
    }
    .btn-area {
      li {
        width: 100%;
        &:nth-of-type(1){
          margin-bottom: 30px;
        }
        p {
          margin-bottom: 10px;
        }
        a {
          max-width: 240px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .table-layout {
      display: block;
      tbody, tr, th, td {
        display: block;
        width: 100%;
      }
      th {
        padding: 15px 0 6px;
        .required {
          font-size: 1.1rem;
        }
      }
      td {
        padding: 0 0 20px;
      }
    }
  }
}
/* ----------------------------------------
 Achievement
---------------------------------------- */
.content-achievement {
  width: 100%;
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  .category-list {
    display: flex;
    justify-content:center;
    ul {
      background: $c-white;
      height: 30px;
      @include border-radius(30px);
      text-align: center;
    }
    li {
      display: inline-block;
      text-align: center;
      &:hover,
      &.active {
        a {
          background: $c-main;
          color: $c-white;
        }
      }
      &:first-child {
        a {
          margin-left: 0;
        }
      }
      a {
        background: $c-white;
        color: $c-black;
        display: flex;
        justify-content:center;
        align-items:center;
        height: 30px;
        margin-left: 4px;
        padding: 0 15px 2px;
        font-weight: bold;
        @include border-radius(30px);
        transition: all 0.3s;
      }
    }
    @media only screen and (max-width: $breakpoint) {
      ul {
        background: none;
        height: auto;
      }
      li {
        margin-bottom: 15px;
      }
    }
  }
  .achievement-list {
    display: flex;
    @include flex-wrap(wrap);
    margin-top: 35px;
    .item-none {
      width: 100%;
      text-align: center;
    }
    a {
      display: block;
      background: $c-white;
      color: $c-black;
      width: calc((340/1110)*100%);
      margin: 15px calc((15/1110)*100%);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      position: relative;
      body:not(.smp) &:hover {
        &:hover {
          &::after {
            border: solid 3px $c-main;
          }
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        border: solid 0px $c-main;
        transition: all 0.1s;
      }
      .icon {
        background: $c-main;
        display: flex;
        justify-content:center;
        align-items:center;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 10px;
        top: 10px;
        @include border-radius(40px);
        svg {
          width: 18px;
          fill: $c-white;
        }
      }
      figure {
        img {
          width: 100%;
        }
      }
      .meta {
        background: $c-main;
        color: $c-white;
        height: 30px;
        padding: 0 15px 2px;
        display: flex;
        justify-content: space-between;
        align-items:center;
      }
      .title {
        font-size: 1.8rem;
        font-weight: bold;
        padding: 15px 15px 18px;
        line-height: 1.33;
      }
    }
    @media only screen and (max-width: $breakpoint) {
      display: block;
      margin-top: 20px;
      padding: 0 20px;
      a {
        display: block;
        background: $c-white;
        color: $c-black;
        width: 100%;
        margin: 20px 0;
        .icon {
          background: $c-main;
          display: flex;
          justify-content:center;
          align-items:center;
          width: 40px;
          height: 40px;
          position: absolute;
          left: 10px;
          top: 10px;
          @include border-radius(40px);
          svg {
            width: 18px;
            fill: $c-white;
          }
        }
        .meta {
          height: 30px;
          padding: 0 15px 2px;
        }
        .title {
          font-size: 1.6rem;
        }
      }
    }
  }
  .wp-pagenavi {
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 40px;
    span,
    a {
      color: $c-black;
      font-weight: bold;
      display: inline-block;
      margin: 0 4px;
      padding: 6px 8px;
      line-height: 1;
      font-size: 1.8rem;
      transition: all 0.2s;
      &:hover,
      &.current {
        color: $c-blue;
        text-decoration: underline;
        &.previouspostslink {
          &::after {
            border-top: solid 3px $c-blue;
            border-left: solid 3px $c-blue;
          }
        }
        &.nextpostslink {
          &::after {
            border-top: solid 3px $c-blue;
            border-right: solid 3px $c-blue;
          }
        }
      }
      &.previouspostslink,
      &.nextpostslink {
        font-style: 0;
        position: relative;
        width: 30px;
        height: 30px;
        margin-top: 2px;
        font-size: 0;
        &::after {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          border-top: solid 3px $c-black;
          margin-top: -8px;
          transition: all 0.2s;
        }
      }
      &.previouspostslink {
        margin-right: 20px;
        &::after {
          margin-left: 2px;
          border-left: solid 3px $c-black;
          transform: rotate(-45deg);
        }
      }
      &.nextpostslink {
        margin-left: 20px;
        &::after {
          margin-left: -4px;
          border-right: solid 3px $c-black;
          transform: rotate(45deg);
        }
      }
    }
  }
  .achievement-content {
    background: $c-white;
    margin-bottom: 50px;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    .icon {
      background: $c-main;
      display: flex;
      justify-content:center;
      align-items:center;
      width: 64px;
      height: 64px;
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 1;
      @include border-radius(64px);
      .icon-in {
        text-align: center;
        p {
          margin-top: 4px;
          color: $c-white;
          font-size: 1.2rem;
          line-height: 1;
          @include en;
        }
      }
      svg {
        width: 20px;
        height: 20px;
        fill: $c-white;
      }
    }
    figure {
      text-align: center;
      img {
        width: 100%;
        &.noimage {
          max-width: 640px;
        }
      }
    }
    .meta {
      display: flex;
      justify-content: space-between;
      align-items:center;
      background: $c-main;
      color: $c-white;
      height: 40px;
      padding: 0 20px 2px;
      font-size: 1.6rem;
    }
    .information {
      padding: 40px 80px 50px;
      .title {
        color: $c-main;
        margin-bottom: 30px;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.33;
        @include mincho;
      }
      .item-list {
        margin-bottom: 40px;
        overflow: hidden;
        border-top: 1px solid $c-main;
        border-bottom: 1px solid $c-main;
        li {
          display: table;
          width: 100%;
          padding: 10px 0;
          border-top: 1px solid #ddd;
          overflow: hidden;
          &:first-child {
            border-top: none;
          }
        }
        .item {
          display: table-cell;
          width: 160px;
          font-weight: bold;
          padding-right: 15px;
        }
        .text {
          display: table-cell;
          vertical-align: middle;
        }
      }
      .comment {
        font-size: 1.6rem;
        text-align: center;
        line-height: 2;
      }
    }
    @media only screen and (max-width: $breakpoint) {
      .icon {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 10px;
        top: 10px;
        @include border-radius(40px);
        .icon-in {
          width: 18px;
          height: 18px;
          p {
            display: none;
          }
        }
        svg {
          vertical-align: top;
          width: 18px;
          height: 18px;
        }
      }
      .meta {
        height: 30px;
        padding: 0 20px 2px;
        font-size: 1.4rem;
      }
      .information {
        padding: 20px 20px 30px;
        .title {
          margin-bottom: 20px;
          font-size: 2.2rem;
          text-align: left;
        }
        .item-list {
          margin-bottom: 20px;
          .item {
            width: 100px;
          }
        }
        .comment {
          font-size: 1.3rem;
          text-align: left;
        }
      }
    }
  }
}
/* ----------------------------------------
 Page 固定ページ基本
---------------------------------------- */
.content-page {
  h2 {
    text-align: left;
    padding-bottom: 0px;
    font-size: 2.8rem;
    margin-top: 40px;
    margin-bottom: 20px;
    border-left: solid 4px $c-main;
    padding-left: 20px;
    font-weight: bold;
    &::after {
      display: none;
    }
  }
  h3 {
    margin: 20px 0 10px;
    text-align: left;
    font-size: 2rem;
    font-weight: bold;
  }
  a {
    &.fancybox {
      display: inline-block;
      margin-right: 5px;
      padding: 2px 5px;
      font-weight: bold;
      font-size: 1.8rem;
    }
  }
}